import React from "react";
import PersonIcon from '@material-ui/icons/Person';
import SimpleCard from "../../components/pageElements/SimpleCard";

export default function (props) {

    const {record} = props

    const title = `Landlord: ${record?.personalData?.name} ${record?.personalData?.surname}`
    return <SimpleCard title={title} avatar={<PersonIcon/>}/>
}