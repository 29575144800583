import Grid from "@material-ui/core/Grid";
import React from "react";

export default function TenancyDetail(props){
    const {model} = props

    return <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        {model.getOutputField("tenantContract.startDate", props)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {model.getOutputField("tenantContract.endDate", props)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {model.getOutputField("tenantContract.fee", props)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {model.getOutputField("tenantContract.beneficiarySignedFile", props)}
                    </Grid>

                </Grid>
}
