import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import WorkflowPageHorizontal from "../WorkflowPageHorizontal";

const stepRegistry = [
    {id: "created", nextTransitions:[{value:"insert_basic_data", label:"Create contract"}], label:"Create contract", showComponent:(props)=><ShowFirstStep record={props.formValue} {...props}/>, editComponent:(props)=><FirstStep {...props}/>},
    {id: "data_uploaded", nextTransitions:[{value:"upload_unsigned", label:"Upload Issuer signed file"}], label:"Upload draft file", showComponent:(props)=><ShowSecondStep record={props.formValue} {...props}/>, editComponent:(props)=><SecondStep {...props}/>},
    {id: "unsigned_uploaded", previousTransitions:["back_to_create"], nextTransitions:[{value:"upload_issuer", label:"Upload beneficiary signed file"}], label:"Upload Contract signed by Issuer", editComponent:(props)=><ThirdStep {...props}/> ,showComponent:(props)=><ShowThirdStep record={props.formValue} {...props}/>},
    {id: "issuer_uploaded", previousTransitions:["back_to_unsigned"], nextTransitions:[{value:"finalize", label: "Finalize contract"}], label:"Finalize contract", editComponent:(props)=><FourthStep {...props}/>, showComponent:(props)=><ShowFourthStep record={props.formValue} {...props}/>,},
    {id: "finalized",previousTransitions:["back_to_issuer"], label:"Summary", editComponent:(props)=><div>
            <Typography>
                All steps completed - you&apos;re finished
            </Typography>
        </div>}]



export default function ContractCreate(props) {
    return <WorkflowPageHorizontal stepRegistry={stepRegistry} standardProps={props} sideElement={<><Typography>Contract</Typography><Summary record={props.formValue} {...props}/></>} />
}

function FirstStep(props){
    const {model} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getInputField("deposit", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("defaultPaymentDay", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("rentFreePeriod", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("fee", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("months", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("startDate", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("endDate", props)}
        </Grid>
    </Grid>
}

function ShowFirstStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getOutputField("unsignedFile", props)}
        </Grid>
    </Grid>
}

function SecondStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getInputField("unsignedFile", props)}
        </Grid>
    </Grid>
}

function ShowSecondStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getOutputField("unsignedFile", props)}
        </Grid>
    </Grid>
}


function ShowThirdStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getOutputField("issuerSignedFile", props)}
        </Grid>
    </Grid>
}

function ThirdStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getInputField("issuerSignedFile", props)}
        </Grid>
    </Grid>
}


function FourthStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getInputField("beneficiarySignedFile", props)}
        </Grid>
    </Grid>
}

function ShowFourthStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getOutputField("beneficiarySignedFile", props)}
        </Grid>
    </Grid>
}


function Summary(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getOutputField("unsignedFile", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getOutputField("issuerSignedFile", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getOutputField("beneficiarySignedFile", props)}
        </Grid>
        <Grid item md={6} xs={6}>
            {model.getOutputField("startDate", props)}
        </Grid>
        <Grid item md={6} xs={6}>
            {model.getOutputField("endDate", props)}
        </Grid>
    </Grid>
}



