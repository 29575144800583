import Grid from "@material-ui/core/Grid";
import React from "react";
import Button from "@material-ui/core/Button";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import {getFromMap} from "react-frontend-generator/dist/utils/mapUtils";
import SimpleCard from "../../../components/pageElements/SimpleCard";

export default function PropertyUnitShow(props){
    const {record, parentRecord, model} = props;
    const {unitLetter, type, id} = record;
    const dispatch = useDispatch();
    const {address, postcode, city, county, country, neighborhood, area} = parentRecord;
    const title = `Room ${unitLetter}`;
    const goToShow = ()=> dispatch(push(`/units/${id}/edit`))

    const fullAddress = `ROOM ${unitLetter}, ${address}, ${neighborhood?.name}, ${postcode.postcode}, ${city.name}, ${county.name}, ${country.name}`;


    const titleElement = <div style={{display:"flex", alignItems:"center"}}>
        <div style={{margin:"0 10px 0 0"}}>{title}</div>
        {model.getOutputField("currentPlace", props, undefined, false)}
    </div>

    const subHeader = <>
        <div>{type?.description}</div>
    </>

    return   <SimpleCard title={titleElement} subTitle={subHeader} action={<Button variant={"contained"} color={"primary"} onClick={goToShow}>Show</Button>}>
        <Grid container spacing={2}>
            <Grid xs={12} md={12} item>{model.getOutputField("images", props)}</Grid>
        </Grid>
    </SimpleCard>
}
