import Grid from "@material-ui/core/Grid";
import React from "react";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {push} from 'connected-react-router';
import SimpleCard from "../../components/pageElements/SimpleCard";

export default function EnquiryCreate(props){
    const {model, submitHandler} = props
    const dispatch = useDispatch();


    const mySubmitHandler = ()=> submitHandler().then(({id})=> dispatch(push(`/enquiries/${id}/edit`)))
    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <SimpleCard title="Prospective tenant details">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("name", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("surname", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("email", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("phoneNumber", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("tenantType", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Grid>
            <Grid item xs={12} md={12}>
                <SimpleCard title="Room/location">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("portal", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("enquiredRoom", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("neighborhood", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Grid>
            <Grid item xs={12} md={12}>
                <SimpleCard title="Period/Price">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {model.getInputField("startDate", props)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {model.getInputField("endDate", props)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {model.getInputField("weeklyPrice", props)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {model.getInputField("holdingDepositPrice", props)}
                    </Grid>
                </Grid>
            </SimpleCard>
            </Grid>
            <Grid item xs={12} md={12}>
                <SimpleCard title="Notes">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("specialRequirements", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Grid>
            <Grid item xs={12} md={12}>
                <Button variant={"contained"} color={"primary"} onClick={mySubmitHandler}>Save</Button>
            </Grid>
        </Grid>
    </>
}
