import React from "react";
import ApartmentIcon from '@material-ui/icons/Apartment';
import SimpleCard from "../../../components/pageElements/SimpleCard";

export default function (props){

    const {propertyName, address} = props.record;

    return   <SimpleCard title={propertyName} avatar={<ApartmentIcon/>}>
        {address}
    </SimpleCard>
}
