import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Transition from "../models/classes/Transition";
import {WorkflowStep} from "../tools/workflow/workflowHelpers";
import WorkflowPageHorizontal from "./WorkflowPageHorizontal";

const CREATED = "created";
const FLOW_TYPE_CHOSEN = "flow_type_chosen";
const DATA_UPLOADED = "data_uploaded";
const UNSIGNED_UPLOADED = "unsigned_uploaded";
const ISSUER_UPLOADED = "issuer_uploaded";
const BENEFICIARY_UPLOADED = "beneficiary_uploaded";
const FINALIZED = "finalized";


export default function ContractWorkflow({uploadData, ...props}) {

    const stepRegistry = [
        new WorkflowStep(CREATED,(props)=><ChooseFlowTypeStep {...props}/>,"Choose flow type"),
        new WorkflowStep(FLOW_TYPE_CHOSEN,(props)=>React.cloneElement(uploadData, props),"Insert basic data",[new Transition("insert_basic_data", "Create holding deposit contract")]),
        new WorkflowStep(DATA_UPLOADED,(props)=><UploadUnsignedFileStep {...props}/>,"Upload unsigned",[new Transition("upload_unsigned", "")]),
        new WorkflowStep(UNSIGNED_UPLOADED,(props)=><UploadIssuerFileStep {...props}/>,"Upload issuer",[new Transition("upload_issuer", "")]),
        new WorkflowStep(ISSUER_UPLOADED,(props)=><UploadBeneficiaryFileStep {...props}/>,"Upload beneficiary",[new Transition("upload_beneficiary", "")]),
        new WorkflowStep(BENEFICIARY_UPLOADED,(props)=><ConfirmationStep {...props}/>,"Confirm",[new Transition("finalize", "")]),
        new WorkflowStep(FINALIZED,(props)=><FinalStep {...props}/>,"")]

    return <WorkflowPageHorizontal stepRegistry={stepRegistry} standardProps={props} embedded />
}

function ChooseFlowTypeStep(props){
    const {formValue, setFormValue, submitHandler, partialSubmitHandler} = props;

    const docusign = (useDocusign) => {
        const newFormValue = formValue.updateFormValue("useDocusign", useDocusign).updateFormValue("requestedTransition", "choose_flow_type");
        console.log("new form value", newFormValue)
        setFormValue(newFormValue)
    }

    return <Grid container>
        <Grid item md={12} xs={12}>
            <Button onClick={()=>docusign(false)} type="submit">Use traditional flow </Button>
            <Button onClick={()=>docusign(true)} type="submit">Use docusign </Button>
        </Grid>
    </Grid>
}

function UploadDataStep({uploadData, ...rest}){
    return React.cloneElement(uploadData, rest)
    /*const {formValue, model} = props;
    const breakingClause = (formValue.hasBreakClause) ? <>
        <Grid item md={12} xs={12}>
            {model.getInputField("party", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("breakExercisable", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("breakNoticePeriod", props)}
        </Grid>
    </> : <></>

    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getInputField("holdingDeposit", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("securityDeposit", props)}
        </Grid>

        <Grid item md={12} xs={12}>
            {model.getInputField("deadline", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("startDate", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("hasBreakClause", props)}
        </Grid>
        {breakingClause}
        <Grid item md={12} xs={12}>
            {model.getInputField("rentFrequency", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("rentFrequencyModality", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("rentPaymentMethod", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("isDepositRequired", props)}
        </Grid>
    </Grid>*/

}


function UploadUnsignedFileStep(props) {
    const {formValue, model} = props;
    if (formValue.useDocusign) {
        return <Grid container>
            Non ti preoccupare giò, il file verrà caricato.
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("unsignedFile", props)}
            </Grid>
        </Grid>
    }
}

function UploadIssuerFileStep(props) {
    const {formValue, model} = props;
    if (formValue.useDocusign) {
        return <Grid container>
            {model.getOutputField("unsignedFile", props)}
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("issuerSignedFile", props)}
            </Grid>
        </Grid>
    }
}

function UploadBeneficiaryFileStep(props) {
    const {formValue, model} = props;
    if (formValue.useDocusign) {
        return <Grid container>
            {model.getOutputField("issuerSignedFile", props)}
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("beneficiarySignedFile", props)}
            </Grid>
        </Grid>
    }
}
function ConfirmationStep(props){
    const {model} = props;
    return <Grid container>
        Please click to confirm
    </Grid>
}

function FinalStep(props) {
    const {model} = props;
    return <Grid container>
        {model.getOutputField("beneficiarySignedFile", props)}
    </Grid>
}




