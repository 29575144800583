import React, {useEffect, useMemo, useState} from "react";
import SimpleCard from "../../components/pageElements/SimpleCard";
import Grid from "@material-ui/core/Grid";
import {TabElement} from "../../components/tabs/TabElement";
import TabberGenerator from "../../components/tabs/TabberGenerator";
import _ from 'lodash';

import Paper from "@material-ui/core/Paper";
import InfoIcon from '@material-ui/icons/Info';
import {useDispatch} from "react-redux";
import {useEdit} from "react-frontend-generator/dist/redux/actions/verbs/edit";
import ActionsMenu from "../../components/buttons/ActionsMenu";
import EmbeddedList from "react-frontend-generator/dist/generators/pages/list/EmbeddedList";
import CreateDeduction from "../landlordRelationship/CreateDeduction";
import {Operation} from "react-frontend-generator/dist/generators/pages/list/listHelpers/Operation";
import {push} from "connected-react-router";
import TenantShow from "./TenantShow";
import {Model} from "react-frontend-generator/dist/resource-models/Model";
import {FieldProps} from "react-frontend-generator/dist/resource-models/models/FieldProps";
import TenantTenancyTimeline from "../../components/tenancyTimelines/TenantTenancyTimeline";
import {FormValue} from "react-frontend-generator/dist/resource-models/formvalue/FormValue";
import TransitionDialog, {TransitionModal} from "./transitions/TransitionDialog";
import ContractWorkflow from "../ContractWorkflow";
import {EditPage} from "react-frontend-generator/dist/generators/pages/EditPageGenerator";
import ActiveTenancyDetail from "./tenancies/ActiveTenancyDetail";
import TFRContract from "./tfrContract/TFRContract";
import PullOut from "./transitions/PullOut";
import Relocation from "./transitions/Relocation";
import RenewSameRoom from "./transitions/RenewSameRoom";
import RenewDifferentRoom from "./transitions/RenewDifferentRoom";
import Transfer from "./transitions/Transfer";
import RenewTenancyEdit from "./tenancies/RenewTenancyEdit";
import {compareWorkflowStates, hasWorkflowState} from "../../tools/workflow/workflowHelpers";
import RenewTenancyDifferentRoomEdit from "./tenancies/RenewTenancyDifferentRoomEdit";

export default function TenantRelationshipShow(props){
    const RENEW_DIFFERENT_ROOM = "renew_different_room";
    const RENEW_SAME_ROOM = "renew_same_room";

    const {record, model, formValue, setFormValue, refresh,partialSubmitHandler} = props;
    const dispatch = useDispatch();
    const {firstTenantTenancy, renewals, activeTenancy, nextTenancy, tenancies} = record;
    const tfrContract = activeTenancy?.tfrContract;
    const resourceName = activeTenancy.resourceName;
    const nextTenancyResourceName = nextTenancy?.resourceName;
    let renderActiveTenancy = <div></div>
    const [modalContent, setModalContent] = useState(<div/>)


    const isRenewalDifferentRoom = (activeTenancy) ? hasWorkflowState(activeTenancy,RENEW_DIFFERENT_ROOM) : false;
    const isRenewSameRoom = (activeTenancy) ? hasWorkflowState(activeTenancy,RENEW_SAME_ROOM) : false;

    const [extendedModel, setExtendedModel] = useState(modelExtender(model, [{id:"activeTenancy", resourceName:resourceName}, {id:"nextTenancy", resourceName: nextTenancyResourceName}]))
    const [extendedFormvalue, setExtendedFormValue] = useState(FormValue.createFromRecord(record, extendedModel));

    useEffect(()=>{setFormValue(extendedFormvalue)},[extendedFormvalue])
    const {edit} = useEdit();
    const newProps =  useMemo(()=> {return new FieldProps({...props, model: extendedModel, formValue:extendedFormvalue, setFormValue: setExtendedFormValue})},[extendedModel, extendedFormvalue, setExtendedFormValue])

    renderActiveTenancy = <ActiveTenancyDetail {...newProps}></ActiveTenancyDetail>

    const markPaymentAsPaid = (id, formvalue)=> edit("landlord_tenancy_payments", id,{...formvalue, requestedTransition:"mark_as_paid"} )
    const payments = activeTenancy?.tenantContract?.payments;
    const paymentsList = payments ? Array.from(payments.values()) : []
    const tenantContract = extendedFormvalue.getPropertyFormValue("activeTenancy.tenantContract.payments");
    const formValuePayments= tenantContract ? Array.from(tenantContract.payments.values()) : [];
    const paymentsModel = (extendedModel.getProperty("activeTenancy.tenantContract.payments").getResource().getModel());

    const tabs = [
        new TabElement({label:"Active tenancy",component:<Grid container spacing={2}>
                <Grid xs={12} md={12} item>
                        {renderActiveTenancy}
                </Grid>
            </Grid>
        }),
        new TabElement({label:"Payments", component:<Grid item xs={12} md={12}>
                {activeTenancy && <SimpleCard>
                    <EmbeddedList
                        title={"Payments"}
                        model={paymentsModel}
                        data={paymentsList}
                        totalItems={paymentsList.length}
                        table={[{id:"fee", label:"Fee"},{ id:"dueDate", label:"Due date"}, { id:"status", label:"Status"}]}
                        itemOperations={[
                            new Operation({color:"primary", text:"show", onClick:(row) => {dispatch(push(`/tenant_tenancy_payments/${row.id}/edit`))}}),
                            new Operation({color:"primary",text:"pay",onClick: (row) => {
                                    const correctElement = formValuePayments.find(item => item.id ===row.id)
                                    markPaymentAsPaid(row.id, correctElement).then(()=>refresh())
                                },visibility:(row) => (row.currentPlace !=="paid"), requiresConfirmation:true
                            }),
                            new Operation({color:"secondary",text:"Xero",onClick:(row) => window.open(row.xeroUrl, "_blank"),
                                visibility:(row) => row.status === 2
                            })
                        ]}
                    />
                </SimpleCard> }

            </Grid>}),
        new TabElement({label:"Tenancies history", component:<Grid item xs={12} md={12}>
                <SimpleCard title="Tenancies history">
                    <TenantTenancyTimeline tenancies={tenancies}/>
                </SimpleCard>
            </Grid>}),
        (tfrContract!==undefined) && new TabElement({label:"Relocation", component:<Grid item xs={12} md={12}>
                <SimpleCard title="Relocation">
                    <Grid container>

                    </Grid>
                </SimpleCard>
            </Grid>}),
        (isRenewalDifferentRoom) && new TabElement({label:"Renewal in different room", component:<Grid item xs={12} md={12}>
                <TransitionModal open={true}>
                    <EditPage propResourceName={nextTenancyResourceName} propId={nextTenancy.id} propEditPage={<RenewTenancyDifferentRoomEdit/>} />
                </TransitionModal>

            </Grid>}),
        (isRenewSameRoom) && new TabElement({label:"Renewal", component:<Grid item xs={12} md={12}>
                <TransitionModal open={true}>
                    <EditPage propResourceName={nextTenancyResourceName} propId={nextTenancy.id} propEditPage={<RenewTenancyEdit/>} />
                </TransitionModal>

            </Grid>})
    ]



    return <>
        <Paper>
            <Grid container>
                <Grid item xs={12} md={4}>
                    {model.getOutputField("tenant", props,<TenantShow/> , false )}
                </Grid>
                <Grid item xs={12} md={4}>
                    {extendedModel.getOutputField("activeTenancy", {...newProps},<UnitDetail></UnitDetail>, false)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleCard
                        title={model.getOutputField("currentPlace", props, undefined, false)}
                        avatar={<InfoIcon/>}
                        action={<ActionsMenu actions={[
                            {onClick: () => setModalContent(<Relocation refresh={refresh} onClose={()=>setModalContent(null)} resourceName={resourceName} id={activeTenancy.id}/>) , text: "Relocate", disabled: (tfrContract!==undefined)},
                            {onClick: () => setModalContent(<PullOut refresh={refresh} onClose={()=>setModalContent(null)} resourceName={resourceName} id={activeTenancy.id}/>), text: "Pull Out"},
                            {onClick: () => setModalContent(<RenewSameRoom refresh={refresh} onClose={() => setModalContent(null)} resourceName={resourceName} id={activeTenancy.id}/>), text: "Renew tenancy"},
                            {onClick: () => setModalContent(<RenewDifferentRoom refresh={refresh} onClose={() => setModalContent(null)} resourceName={resourceName} id={activeTenancy.id}/>), text: "Renew tenancy to different room"},
                            {onClick: () => setModalContent(<Transfer refresh={refresh} onClose={()=>setModalContent(null)} resourceName={resourceName} id={activeTenancy.id}/>), text: "Transfer"},
                            {onClick: () => console.log("renew"), text: "Client replacement"},
                            {onClick: () => {}, text: "One month notice"},
                        ]}/>}>
                    </SimpleCard>
                </Grid>


            </Grid>
        </Paper>
        <TabberGenerator tabs={tabs}/>
        {modalContent}
    </>



}


function UnitDetail(props){
    const {model} = props
    return model.getOutputField("unit", props, <UnitDetailDetail></UnitDetailDetail>)
}

function UnitDetailDetail(props){
    const {model} = props
    return model.getOutputField("unitLetter", props)
}





function modelExtender(model, extension = []){

    const newModels = extension
        .filter(({id, resourceName})=> resourceName !==undefined)
        .map(({id, resourceName})=>{
            const embeddedProperties = model.properties.filter(property => property.type == "embedded_multiple")
            const embeddedModel  = _.cloneDeep(embeddedProperties.find(property => property.resourceName == resourceName));
            return embeddedModel.getEmbeddedSingleModel(id);
        })
    const newProperties = [...model.properties, ...newModels ];
    return new Model(newProperties);

}

