import Grid from "@material-ui/core/Grid";
import React from "react";
import ContractWorkflow from "../../ContractWorkflow";
import {CopyableLink} from "./TenantHoldingDepositStep";

export function TenantCompleteIfrStep(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getInputField("ifrContract", props, <ContractWorkflow uploadData={<UploadDataStep/>}  />)}
        </Grid>
    </Grid>

}

export function PayMoveInStep(props){
    const {model, record} = props;
    const tenant = record.tenant.personalData;
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            The unique payment url has already been sent to {tenant.name} {tenant.surname} at the email address <a href={`mailto:${tenant.email}`}>{tenant.email}</a>
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getOutputField("moveInPaymentUrl", props, <MoveInMoneyUrl></MoveInMoneyUrl>)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getOutputField("moveInPaid", props, <MoveinMoneyShow></MoveinMoneyShow>)}
        </Grid>
    </Grid>

}

export function ChooseReferencingStep(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getInputField("isReferenced", props)}
        </Grid>
    </Grid>

}

export function ChooseDepositTypeStep(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getInputField("useReposit", props)}
        </Grid>
    </Grid>

}

function UploadDataStep(props){
    const {model} = props
    return <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            {model.getInputField("moveInDate", props)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getInputField("moveOutDate", props)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getInputField("securityDeposit", props)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getInputField("weeklyFee", props)}
        </Grid>
    </Grid>
}


function MoveinMoneyShow({record}){

    return <div>
        Status of the payment: {(record) ? "Move in money paid" : "Move in money not paid"}
    </div>
}

function MoveInMoneyUrl(props){
    return <Grid container>
        <Grid item xs={6}>
            <CopyableLink link={props.record}/>
        </Grid>
    </Grid>
}

