import React from "react";
import Section from "../../components/pageElements/Section";
import Grid from "@material-ui/core/Grid";
import PaymentItemShow from "./paymentItem/PaymentItemShow";

export default function (props){

    const {model} = props

    return <div>
        <Section title={props.record.info.propertyName}>
            <Grid container spacing={2}>
                <Grid xs={12} md={4} item>{model.getOutputField("type", props)}</Grid>
                <Grid xs={12} md={4} item>{model.getOutputField("status", props)}</Grid>
                <Grid xs={12} md={4} item>{model.getOutputField("dueDate", props)}</Grid>
                <Grid xs={12} md={12} item>{model.getOutputField("paymentItems", {...props, showElement:<PaymentItemShow/>})}</Grid>
            </Grid>
        </Section>
    </div>
}