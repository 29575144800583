import Grid from "@material-ui/core/Grid";
import React from "react";

export default function (props){
    const {model} = props
    return <Grid container>
        <Grid item xs={3} md={3}>
            {model.getOutputField("description", props)}
        </Grid>
        <Grid item xs={3} md={3}>
            {model.getOutputField("quantity", props)}
        </Grid>
        <Grid item xs={3} md={3}>
            {model.getOutputField("unitAmount", props)}
        </Grid>
    </Grid>
}