import React from "react";
import Grid from "@material-ui/core/Grid";

export function LivingRoom(props){
    const {model}=props
    return <Grid container>
        <Grid item xs={6} md={6}>
            {model.getInputField("diningArea", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("sofa", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("TV", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("coffeeTable", props)}
        </Grid>
        <Grid item xs={12} md={12}>
            {model.getInputField("images", props)}
        </Grid>

    </Grid>
}
