import property from "./property";
import area from "./area";
import unit from "./unit";
import landlord from "./landlord";
import landlordProperty from "./landlordProperty";
import bankAccount from "./bankAccount";
import contract from "./contract";
import landlordTenancy from "./landlordTenancy";
import landlordTenancyPayment from "./landlordTenancyPayment";
import defaultPaymentItem from "./defaultPaymentItem";
import agent from "./agent";
import landlordContract from "./landlordContract";
import negotiation from "./negotiation";
import landlordRelationship from "./landlordRelationship";
import tenantRelationship from "./tenantRelationship";
import firstLandlordTenancy from "./firstLandlordTenancy";
import user from "./user";
import invoice from "./invoice";
import portal from "./portal";
import renewalLandlordTenancy from "./renewalLandlordTenancy";
import enquiryOverride from "./enquiry";
import tenantOverride from "./tenant";
import postcodeOverride from "./postcode";
import citiesOverride from "./city";
import neighborhoodOverride from "./neighborhood";

export const overrideRegistry = {
    properties: property,
    areas: area,
    agents: agent,
    units: unit,
    landlords: landlord,
    landlord_properties: landlordProperty,
    landlord_contracts: landlordContract,
    bank_accounts: bankAccount,
    contracts: contract,
    landlord_tenancies: landlordTenancy,
    landlord_tenancy_payments: landlordTenancyPayment,
    default_payment_items: defaultPaymentItem,
    negotiations: negotiation,
    landlord_relationships: landlordRelationship,
    first_landlord_tenancies: firstLandlordTenancy,
    tenant_relationships: tenantRelationship,
    portals: portal,
    users: user,
    invoices: invoice,
    renewal_landlord_tenancies: renewalLandlordTenancy,
    enquiries: enquiryOverride,
    tenants: tenantOverride,
    postcodes:postcodeOverride,
    cities:citiesOverride,
    neighborhoods:neighborhoodOverride
}
