import {isAnyStateMachineStates} from "../../tools/workflow/workflowHelpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import EnquiryEdit from "./EnquiryEdit";

export function EnquiryView(props){
    const {record} = props;
    const isNotPending = isAnyStateMachineStates(record,  "approved", "rejected")

    if(record.currentPlace){
        return (!isNotPending) ? <EnquiryEdit {...props}/> : <div>The enquiry has been {record.currentPlace}</div>
    }
    return <div>
        <CircularProgress />
    </div>
}
