import Grid from "@material-ui/core/Grid";
import React from "react";

export default function TFRContract(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item xs={12} md={6}>{model.getInputField("transferDate", props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("moveOutDate", props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("newUnit", props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("weeklyFee", props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("securityDeposit", props)}</Grid>
    </Grid>
}