import {getThemeByName} from "../style/themes";

export const CHANGE_RESOURCE = "CHANGE_RESOURCE";
export const CHANGE_RESOURCE_BUFFER = "CHANGE_RESOURCE_BUFFER";
export const SWITCH_THEME = "SWITCH_THEME";

export const changeResource = (resource) =>{
    return {
        type: CHANGE_RESOURCE,
        resource: resource
    }
}

export const switchTheme = (themeName) => {
    const newTheme = getThemeByName(themeName);
    return {
        type: SWITCH_THEME,
        theme: newTheme
    }
}
export const switchThemeBool = () => {
    const newTheme = getThemeByName();
    return {
        type: SWITCH_THEME,
        theme: newTheme
    }
}

