import Grid from "@material-ui/core/Grid";
import SaveButton from "../../components/buttons/SaveButton";

export default function (props){
    const {model, submitHandler} = props
    return <Grid container spacing={2}>
        <Grid item xs={6}>
            {model.getInputField("name", props)}
        </Grid>
        <Grid item xs={6}>
            {model.getInputField("type", props)}
        </Grid>
        <Grid item xs={6}>
            {model.getInputField("category", props)}
        </Grid>
        <Grid item xs={6}>
            {model.getInputField("fee", props)}
        </Grid>
        <SaveButton onClick={submitHandler}>Save</SaveButton>
    </Grid>


}