import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import WorkflowPageVertical from "../WorkflowPageVertical";
import Transition from "../../models/classes/Transition";
import LandlordRenewalContractCreate from "./landlordRenewalContract/LandlordRenewalContractCreate";
import {WorkflowStep} from "../../tools/workflow/workflowHelpers";

const CREATED = "created";
const RELATIONSHIP_CHOSEN = "relationship_chosen";
const READY = "ready";
const ACTIVE = "active";
const EXPIRED = "expired";

const CHOOSE_RELATIONSHIP = "choose_relationship";
const TURN_TO_READY = "turn_to_ready";

const stepRegistry = [
    new WorkflowStep(CREATED, (props)=><ChooseRelationshipStep {...props}/>, "", [new Transition(CHOOSE_RELATIONSHIP, "")],(props)=><ShowFirstStep record={props.formValue} {...props}/> ),
    new WorkflowStep(RELATIONSHIP_CHOSEN, (props)=><FillContractStep {...props}/>, "" ),
    new WorkflowStep(READY, (props)=><ReadyStateStep {...props}/>, ""),
    new WorkflowStep(ACTIVE, (props)=><ActiveStateStep {...props}/>, ""),
    new WorkflowStep(EXPIRED,(props)=><ExpiredStateStep {...props}/>, "")
]


export default function RenewalLandlordTenancyCreate(props){
    return <WorkflowPageVertical standardProps={props} stepRegistry={stepRegistry} />
}


function ChooseRelationshipStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getInputField("landlordRelationship", props)}
        </Grid>
    </Grid>
}

function ShowFirstStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getOutputField("landlordRelationship", props)}
        </Grid>
    </Grid>
}

function FillContractStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("landlordContract", {...props, form:<LandlordRenewalContractCreate/>})}
        </Grid>
    </Grid>
}


function ShowSecondStep(props){
    return <Typography>Does the property have to go through an acquisition process?</Typography>
}


function ReadyStateStep(props){
    const {model} = props
    const {formValue} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            <Typography>The tenancy is ready. It will become active on </Typography>
            {model.getOutputField("landlordContract.startDate", props)}
        </Grid>
    </Grid>
}

function ActiveStateStep(props){
    return <Grid container>
        <Typography>The tenancy is active</Typography>
    </Grid>
}

function ExpiredStateStep(){
    <Typography>The tenancy is expired</Typography>
}




