import TransitionDialog, {TransitionModal} from "./TransitionDialog";
import React, {useEffect, useState} from "react";
import {EditPage} from "react-frontend-generator/dist/generators/pages/EditPageGenerator";



export default function ({resourceName, id, onClose, refresh}){
    const [open, setOpen] = useState(true)
    const onCloseHandler = () => {
        onClose()
        setOpen(!open)
        refresh()
    }
    const [onClick, setOnClick] = useState(()=>console.log("selected"));


    return <TransitionModal open={open} handleClose={onCloseHandler} onClick={onClick}>
        <EditPage propResourceName={resourceName} propId={id} propEditPage={<PullOutForm  setOnClick={setOnClick}></PullOutForm>}/>
    </TransitionModal>
}

function PullOutForm(props){
    const {model, record, formValue, partialSubmitHandler, setOnClick} = props

    useEffect(()=>{
        setOnClick( ()=> ()=> partialSubmitHandler(formValue.updateFormValue("requestedTransition", "pull_out")))
    },[formValue])

    return model.getInputField("tenantContract.endDate", props)
}