import React from "react";
import Grid from "@material-ui/core/Grid";
import ContractWorkflow from "../../ContractWorkflow";

export default function (props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            {model.getInputField("unit", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("tenantContract", props, <ContractWorkflow uploadData={<UploadDataStep/>}  />)}
        </Grid>
    </Grid>

}

function UploadDataStep(props){
    const {model} = props
    return <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            {model.getInputField("startDate", props)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getInputField("endDate", props)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getInputField("fee", props)}
        </Grid>
    </Grid>
}