import TableItem from "../classes/TableItem";
import React from "react";
import AreaCreate from "../../pages/area/AreaCreate";


const areaOverride = {
    table:[new TableItem("name", "Area name"), new TableItem("city", "City name")],
    createPage:<AreaCreate/>,
    editPage:<AreaCreate/>,
}

export default areaOverride
