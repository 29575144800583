import withStyles from "@material-ui/core/styles/withStyles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import {Link, Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {
    changeToReadableFormat,
    getDateBackendFormat,
    getDateInputFormat,
    getFormTimeFormat
} from "../../utils/timeManager";
import Button from "@material-ui/core/Button";

import moment from "moment";
import {authProvider} from "../../utils/authorizationProvider";
import Badge from "@material-ui/core/Badge";
import {useList} from "react-frontend-generator/dist/redux/actions/verbs/list";
import {useEdit} from "react-frontend-generator/dist/redux/actions/verbs/edit";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        "white-space": "unset",
        "max-height": 90
    },
}))(MenuItem);



const useStyles = makeStyles((theme) => ({
    pink: {
        color: theme.palette.getContrastText("#ff7977"),
        backgroundColor: "#ff7977",
    },
    notificationReadMoreButton:{
        width:"100%"
    }
}));

export default function NotificationsList() {

    const {get} = useList();
    const {edit} =  useEdit();
    const [notificationsToShow, setNotificationsToShow] = useState([]);
    const [newNotificationsNumber, setNewNotificationsNumber] = useState(0);
    const {id} = authProvider.getIdentity();
    const [page, setPage] = useState(30);
    const [notificationsNotOver, setNotificationsNotOver] = useState(true)

    const MINUTE_MS = 3600000;

    const checkNotifications=()=>{
        edit("users", id, {lastNotificationCheckTime: getFormTimeFormat(moment())})
    }

    const getNotifications = () => get("notification_users",1,{itemsPerPage:page}).then(({list,totalItems})=> {
        if(list && list.length === totalItems){
            setNotificationsNotOver(false);
        }
        setNotificationsToShow(list ?? [])
    })


    useEffect(()=>{
        getNotifications();
    },[page])

    useEffect(()=>{
        const interval = setInterval(() => {
            getNotifications()
        }, MINUTE_MS );
        return () => clearInterval(interval);
    },[page])

    useEffect(()=>{
        const newNotificationsNumber = notificationsToShow.filter(notification => notification.isNew).length;
        setNewNotificationsNumber(newNotificationsNumber);
    },[notificationsToShow])

    const editNotification = (id) => {

        const readAtTime = getDateInputFormat();
        changeNotificationStatus(id,readAtTime);
        edit("notification_users",id, {readAt: getDateBackendFormat()}, false)
    }

    const changeNotificationStatus = (id, readAt) => {
        notificationsToShow.forEach(notification => {
            if(notification.id===id){
                notification.readAt = readAt;
            }
        });
        const newNotifications = [...notificationsToShow];
        setNotificationsToShow(newNotifications);


    }

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        checkNotifications(moment.now());
        setNewNotificationsNumber(0)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleClick}
            >
                <Badge badgeContent={newNotificationsNumber} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <StyledMenu
                MenuListProps={{disableListWrap:true}}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    notificationsToShow.map(notification => <StyledMenuItem onClick={()=>editNotification(notification.id)}>
                        <div style={{display:"flex", alignItems:"center"}} >
                            <div style={{padding:10}}>
                                <Avatar className={classes.pink}>
                                    <InboxIcon />
                                </Avatar>
                            </div>
                            <div style={{maxWidth:300, padding:10}}>
                                <Link color="secondary" href={notification.notification.path}>{notification.notification.text}</Link>
                                <Typography variant={"body2"}>{changeToReadableFormat(notification.notification.sentAt, "YYYY-MM-DD")}</Typography>
                            </div>
                            <div>
                                {!("readAt" in notification) && <Avatar >
                                    <PriorityHighIcon/>
                                </Avatar>}
                            </div>
                        </div>
                    </StyledMenuItem>)
                }
                <StyledMenuItem>
                    {notificationsNotOver && <Button color="primary" variant="contained" className={classes.notificationReadMoreButton}
                             onClick={() => setPage(page * 2)}>See more</Button>}
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}
