import React from "react";
import Grid from "@material-ui/core/Grid";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function (props){
    const {model,submitHandler, loading}= props
    return <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            {model.getInputField("name", props)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getInputField("country", props)}
        </Grid>
        <SaveLoaderButton loading={loading} onClick={submitHandler}>Save</SaveLoaderButton>
    </Grid>
}
