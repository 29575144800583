import NegotiationOfferCreate from "../../pages/createPages/NegotiationOfferCreate";
import NegotiationCreate from "../../pages/createPages/NegotiationCreate";

const negotiationOverride = {
    "model":{
        "negotiationOffers":{"form":<NegotiationOfferCreate/>, "modifyOnlyLastElement": true, editabilityRule: (item) => item.currentPlace==="created"|| item.currentPlace===undefined}
    },
    "createPage": <NegotiationCreate/>,
    "editPage": <NegotiationCreate/>
}

export default negotiationOverride