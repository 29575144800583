import React from "react";
import {Link} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import PersonIcon from '@material-ui/icons/Person';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

export default function ({landlord}){
    const {id, name, surname, email, fullName,  searchKey} = landlord;
    return <>
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <PersonIcon></PersonIcon>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Link color="secondary" href={`/${searchKey}/${id}/edit`}>{fullName}</Link>} secondary={email} />
        </ListItem>
        <ListItem>
    </ListItem>
    </>
}