import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ApartmentIcon from '@material-ui/icons/Apartment';
export default function ({property}){
    const dispatch = useDispatch();
    const {id, propertyName, address, neighborhood, area, searchKey} = property;
    return <>
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <ApartmentIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Link color="secondary" href={`/${searchKey}/${id}/edit`}>{propertyName}</Link>} secondary={address} />
        </ListItem>
    </>
}