import TableItem from "../classes/TableItem";
import React from "react";
import UserCreatePage from "../../pages/UserCreatePage";

const usersOverride = {
    table:[new TableItem("email", "Email"),new TableItem("roles", "Roles")],
    createPage:<UserCreatePage/>
}

export default usersOverride
