import React from "react";
import FirstLandlordTenancyShow from "./FirstLandlordTenancyShow";
//import SimpleCard from "../../components/pageElements/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {TabElement} from "../../components/tabs/TabElement";
import TabberGenerator from "../../components/tabs/TabberGenerator";
import LandlordShow from "./LandlordShow";
import SmallPropertyShow from "./property/SmallPropertyShow";
import Paper from "@material-ui/core/Paper";
import InfoIcon from '@material-ui/icons/Info';
import {useDispatch} from "react-redux";
import {useEdit} from "react-frontend-generator/dist/redux/actions/verbs/edit";
import ActionsMenu from "../../components/buttons/ActionsMenu";
import {hasWorkflowState} from "../../tools/workflow/workflowHelpers";
import EmbeddedList from "react-frontend-generator/dist/generators/pages/list/EmbeddedList";
import CreateDeduction from "../landlordRelationship/CreateDeduction";
import {Operation} from "react-frontend-generator/dist/generators/pages/list/listHelpers/Operation";
import TenancyTimeline from "../../components/tenancyTimelines/TenancyTimeline";
import {push} from "connected-react-router";
import SimpleCard from "../../components/pageElements/SimpleCard";

export default function LandlordRelationshipShow(props){

    const {record, model, formValue, refresh} = props;
    const dispatch = useDispatch();
    const {firstLandlordTenancy, renewals, activeTenancy, tenancies} = record;

    const {edit} = useEdit();

    const markPaymentAsPaid = (id, formvalue)=> edit("landlord_tenancy_payments", id,{...formvalue, requestedTransition:"mark_as_paid"} )

    const payments = firstLandlordTenancy?.landlordContract?.payments;
    const paymentsList = payments ? Array.from(payments.values()) : []

    const landlordContract = formValue.getPropertyFormValue("firstLandlordTenancy.landlordContract.payments");
    const formValuePayments= landlordContract?.payments ? Array.from(landlordContract.payments.values()) : [];

    const canRenew = hasWorkflowState(record, ["active", "expiring"])
    const paymentsModel = (model.getProperty("firstLandlordTenancy.landlordContract.payments").getResource().getModel());

    const tabs = [
        new TabElement({label:"Active tenancy",component:<Grid container spacing={2}>
                <Grid xs={12} md={12} item>
                    <SimpleCard title={"Active Tenancy"}>
                        {activeTenancy ? model.getOutputField("firstLandlordTenancy", props, <FirstLandlordTenancyShow></FirstLandlordTenancyShow>, false) : <Typography>No active tenancy was found.</Typography>}
                    </SimpleCard>
                </Grid>
            </Grid>
        }),
        new TabElement({label:"Payments", component:<Grid item xs={12} md={12}>
                {activeTenancy && <SimpleCard action={<CreateDeduction {...props}/>}>
                    <EmbeddedList
                        title={"Payments"}
                        model={paymentsModel}
                        data={paymentsList}
                        totalItems={paymentsList.length}
                        table={[{id:"payableAmount", label:"Fee"},{ id:"dueDate", label:"Due date"}, { id:"status", label:"Status"}]}
                        itemOperations={[
                            new Operation({color:"primary", text:"show", onClick:(row) => {dispatch(push(`/landlord_tenancy_payments/${row.id}/edit`))}}),
                            new Operation({color:"primary",text:"pay",onClick: (row) => {
                                const correctElement = formValuePayments.find(item => item.id ===row.id)
                                markPaymentAsPaid(row.id, correctElement).then(()=>refresh())
                            },visibility:(row) => (row.currentPlace !=="paid"), requiresConfirmation:true
                            }),
                            new Operation({color:"secondary",text:"Xero",onClick:(row) => window.open(row.xeroUrl, "_blank"),
                                visibility:(row) => row.status === 2
                            })
                        ]}
                    />
                </SimpleCard> }

            </Grid>}),
        new TabElement({label:"Tenancies history", component:<Grid item xs={12} md={12}>
                <SimpleCard title="Tenancies history">
                    <TenancyTimeline tenancies={tenancies}></TenancyTimeline>
                </SimpleCard>
            </Grid>})
    ]



    return <>
        <Paper>
            <Grid container>
                <Grid item xs={12} md={4}>
                        {model.getOutputField("landlord", props,<LandlordShow/> , false )}
                </Grid>
                <Grid item xs={12} md={4}>
                    {model.getOutputField("property", {...props},<SmallPropertyShow/>, false )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleCard
                        title={model.getOutputField("currentPlace", props, undefined, false)}
                        avatar={<InfoIcon/>}
                        action={<ActionsMenu actions={[
                            {onClick: () => console.log("renew"), text: "Renew tenancy", disabled: !canRenew},
                            {onClick: () => console.log("renew"), text: "Close tenancy"}
                            ]}/>}>
                    </SimpleCard>
                </Grid>


            </Grid>
        </Paper>
        <TabberGenerator tabs={tabs}/>
    </>

}


