import Grid from "@material-ui/core/Grid";
import React from "react";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function (props){
    const {model, loading, submitHandler} = props
    return <Grid container spacing={2}>
        <Grid item xs={12} md={6}>{model.getInputField("name",props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("discount",props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("isPushIntegration",props)}</Grid>
        <Grid item xs={12} md={6}>{model.getInputField("url",props)}</Grid>
        <SaveLoaderButton loading={loading} onClick={submitHandler}>Save</SaveLoaderButton>
    </Grid>
}
