import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";

export default function ({property}){
    const dispatch = useDispatch();
    const {id, propertyName, address, neighborhood, area, searchKey} = property;
    return <>
        <ListItem>
        <Link color="secondary" href={`/${searchKey}/${id}/edit`}>{address}</Link>
    </ListItem>
    </>
}