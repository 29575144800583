import {useDispatch} from "react-redux";
import React, {useMemo} from "react";
import {push} from "connected-react-router";
import ButtonsHorizontalList from "../../components/buttons/ButtonsHorizontalList";
import Button from "@material-ui/core/Button";
import {Operation} from "react-frontend-generator/dist/generators/pages/list/listHelpers/Operation";
import {RouteFilterList} from "react-frontend-generator/dist/generators/pages/ListPageGenerator";

export function EnquiryList (){
    const dispatch = useDispatch();
    const presetFilters = useMemo(()=>{return {
        currentPlace: "created"
    }},[])

    const itemOperations = [
        new Operation({color:"primary", text:"edit", onClick: (row) => dispatch(push(`/enquiries/${row.id}/edit`))})
    ]

    return <>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <ButtonsHorizontalList>
                <Button color="primary" variant="contained" onClick={()=> dispatch(push(`/enquiries/new`))}>New</Button>
                <Button color="primary" onClick={()=> dispatch(push(`/enquiries/new`))}>Refresh</Button>
            </ButtonsHorizontalList>

        </div>
        <RouteFilterList resourceName={"enquiries"} filters={presetFilters} itemOperations={itemOperations}/>
    </>
}
