import React, {useEffect, useState} from "react";
import {TransitionModal} from "./TransitionDialog";
import {EditPage} from "react-frontend-generator/dist/generators/pages/EditPageGenerator";
import {Typography} from "@material-ui/core";

export default function ({resourceName, id, onClose, refresh}){
    const [open, setOpen] = useState(true)
    const onCloseHandler = () => {
        onClose()
        setOpen(!open)
    }
    const [onClick, setOnClick] = useState(()=>console.log("selected"));


    return <TransitionModal open={open} handleClose={onCloseHandler} onClick={onClick}>
        <EditPage propResourceName={resourceName} propId={id} propEditPage={<RenewDifferentRoomForm refresh={refresh} setOnClick={setOnClick}></RenewDifferentRoomForm>}/>
    </TransitionModal>
}

function RenewDifferentRoomForm(props){
    const {model, record, formValue, partialSubmitHandler, setOnClick} = props

    useEffect(()=>{
        setOnClick( ()=> ()=> partialSubmitHandler(formValue.updateFormValue("requestedTransition", "renew_different_room")))
    },[formValue])

    return <Typography>This will help you create a renewal for this tenancy. Please, press the confirm button if you're sure what you're doing</Typography>

}