import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function (props){
    return <Button size="large" style={{position:"fixed", bottom:30, right:30}} variant="contained" color={"secondary"} {...props}/>
}

export function MyCircularProgress(props){
    const classes = useStyles();
    return <CircularProgress size={24} className={classes.buttonProgress} />
}

export const withLoader = (element, loading) => {
    const nestedChildren = React.Children.map(element, (child => child.props.children))
    return React.cloneElement(element, {disabled:loading}, [nestedChildren, <div>{loading && <MyCircularProgress/> }</div> ])
}
