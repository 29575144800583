import React from "react";
import Section from "../../components/pageElements/Section";
import Grid from "@material-ui/core/Grid";
import PaymentItemCreate from "./PaymentItemCreate";
import PaymentItemShow from "../showPages/paymentItem/PaymentItemShow";
import SimpleCard from "../../components/pageElements/SimpleCard";

export default function (props){
    const {model, record} = props
    const {paymentDetails} = record;
    const title = paymentDetails ? `Payment to ${paymentDetails.landlord} for ${paymentDetails.property}, tenancy ${paymentDetails.landlordRelationship} ` : "";
    const paymentItems = (record.status !==3) ? model.getInputField("paymentItems", props, <PaymentItemCreate/>) : model.getOutputField("paymentItems", props, <PaymentItemShow></PaymentItemShow>)
    return <div>
        <SimpleCard title={title} action={
            <>
                {model.getOutputField("status", props, undefined, false)}
                {model.getOutputField("type", props, undefined, false)}
            </>
        }>
            <Grid container spacing={2}>
                <Grid xs={12} md={6} item>
                    {model.getOutputField("dueDate", props)}
                </Grid>
                <Grid xs={12} md={12} item>
                    {paymentItems}
                </Grid>
            </Grid>
        </SimpleCard>
    </div>
}