import React, {useCallback, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import StepContent from "@material-ui/core/StepContent";
import _ from 'lodash';
import {FormValue} from "react-frontend-generator/dist/resource-models/formvalue/FormValue";
import {Record} from "react-frontend-generator/dist/resource-models/Record";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    cardSxRoot:{
        width: '100%',
        height:"100%",
        display: "flex",
        flexDirection: "column"
    },
    cardSxContent:{
        flexGrow:1
    }
}));

export default function WorkflowPageVertical({standardProps:props, stepRegistry, sideElement, single=true}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {formValue, setFormValue, model} = useMemo(()=>{return props;},[props]);
    const [activeStep, setActiveStep] = React.useState(0); //frontend step
    const [currentStep, setCurrentStep] = React.useState(0); //step corresponding to current transition
    const [skipped, setSkipped] = React.useState(new Set());

    const setCurrentStepFunction = useCallback(()=>{
        if(formValue.currentPlace){
            const newCurrentStep = getCurrentStep(formValue, single, stepRegistry)
            setCurrentStep(newCurrentStep)
        }
    },[formValue])

    const moveToNextState = (nextTransitionIndex) => {

        const {formValue, partialSubmitHandler} = props;
        const transitionValue = stepRegistry[currentStep]["nextTransitions"][nextTransitionIndex]["value"]
        const newFormValue = formValue.updateFormValue("requestedTransition", transitionValue)

        partialSubmitHandler(newFormValue).then((responseFormvalue) => {

            if(responseFormvalue.id){
                const newActiveStep = getCurrentStep(responseFormvalue,single, stepRegistry)
                setActiveStep(newActiveStep)
                const record = Record.createFromJson(responseFormvalue, model);
                const formValue = FormValue.createFromRecord(record,model)
                setFormValue(formValue)
            }else{
                dispatch(push(`/${props.resourceName}/${responseFormvalue.id}/edit`))
            }

        }).catch((e)=>{
            console.error("BAAD", e);
        });
    }

    const moveToPreviousState = (previousTransitionIndex) => {
        const {formValue, partialSubmitHandler} = props;
        formValue.requestedTransition = stepRegistry[activeStep+1]["previousTransitions"][previousTransitionIndex];
        partialSubmitHandler(formValue).then((responseFormvalue) => {
            setFormValue(responseFormvalue);
        }).catch(()=>{
        });
    }


    const getStepContent = (activeStep, currentStep, props) => {
        if(activeStep < currentStep){
            return <div>{stepRegistry[activeStep].showComponent(props)}</div>
        }
        return stepRegistry[activeStep].editComponent(props);
    }

    const getStepPreviousButtons = (activeStep) => {
        return stepRegistry[activeStep].previousTransitions.map((previousTransition, previousTransitionIndex) => <Button onClick={()=>moveToPreviousState(previousTransitionIndex)}>EDIT</Button>)
    }

    const getStepNextButtons = (activeStep) => {

        if(stepRegistry[activeStep].nextTransitions.length!==1){
            return stepRegistry[activeStep].nextTransitions.map((nextTransition, nextTransitionIndex) => <Button
                variant="contained"
                color="primary"
                onClick={()=> handleNext(nextTransitionIndex)}
                className={classes.button}
            >
                {activeStep === steps.length - 2 ? nextTransition.label : nextTransition.label}
            </Button>)
        }else{
            return stepRegistry[activeStep].nextTransitions.map((nextTransition, nextTransitionIndex) => <Button
                variant="contained"
                color="primary"
                onClick={()=> handleNext(nextTransitionIndex)}
                className={classes.button}
            >
                {activeStep === steps.length - 2 ? 'Finish' : "Next"}
            </Button>)
        }

    }


    useEffect(()=> setCurrentStepFunction(),[formValue])
    useEffect(()=>{setActiveStep(currentStep)},[currentStep])


    const steps = stepRegistry.map((step) => step.label);

    const isStepOptional = (step) => {
        return false;
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = (nextTransitionIndex) => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if(currentStep===activeStep){
            moveToNextState(nextTransitionIndex);
        }else{
            setActiveStep(activeStep => activeStep+1)
        }


    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item md={ sideElement ? 8 : 12}>
                <Card className={classes.cardSxRoot}>
                    <CardContent className={classes.cardSxContent}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                        <StepContent>
                                            {activeStep === steps.length ? (
                                                <div>
                                                    <Typography className={classes.instructions}>
                                                        All steps completed
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Typography className={classes.instructions}>{getStepContent(activeStep, currentStep, props)}</Typography>
                                                </div>
                                            )}
                                            {/*<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>*/}
                                            {isStepOptional(activeStep) && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSkip}
                                                    className={classes.button}
                                                >
                                                    Skip
                                                </Button>
                                            )}
                                            {activeStep < currentStep && <Button onClick={moveToPreviousState}>EDIT</Button>}

                                            {activeStep < steps.length-1 &&
                                            getStepNextButtons(activeStep)}
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </CardContent>
                    <CardActions>

                    </CardActions>
                </Card>
            </Grid>
            {sideElement && <Grid item md={4}>
                <Card className={classes.root}>
                    <CardContent>
                        {sideElement}
                    </CardContent>
                </Card>
            </Grid>}
        </Grid>

    );
}


function getCurrentStep(formValue, single, stepRegistry){
    const keys = Object.keys(formValue.currentPlace);
    const element =  single ? stepRegistry.find(item => item.id === formValue.currentPlace) : stepRegistry.find(item => (_.difference(item.id, keys).length === 0 && _.difference(keys,item.id).length === 0) ) ;
    return stepRegistry.indexOf(element)
}
