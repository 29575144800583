import React from 'react';
import Grid from "@material-ui/core/Grid";
import LandlordContractCreate from "./LandlordContractCreate";
import Transition from "../../models/classes/Transition";
import HoldingDepositContractCreate from "../landlordFirstTenancy/holdingDepositContract/HoldingDepositContractCreate";
import WorkflowPageHorizontal from "../WorkflowPageHorizontal";
import {WorkflowStep} from "../../tools/workflow/workflowHelpers";

const CREATED = "created";
const HOLDING_DEPOSIT_CREATED = "holding_deposit_created";
const CONTRACT_CREATED = "contract_created";
const READY = "ready";
const ACTIVE = "active";
const EXPIRED = "expired";

const stepRegistry = [
    new WorkflowStep(CREATED, (props)=><HoldingDepositStep {...props}/>,"Holding Deposit Contract" ),
    new WorkflowStep(HOLDING_DEPOSIT_CREATED, (props)=><LandlordContractStep {...props}/>,"Landlord Contract" ),
    new WorkflowStep(CONTRACT_CREATED, (props)=><PayMoveInMoneyStep {...props}/>,"Move in money" ,[new Transition("turn_to_ready", "activate")]),
    new WorkflowStep(READY, (props)=><ReadyStep {...props}/> ,"Tenancy ready")
]


export default function FirstLandlordTenancyCreate(props){
    return <WorkflowPageHorizontal embedded={true} isLinear={false} stepRegistry={stepRegistry} standardProps={props}/>
}

function HoldingDepositStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("holdingDepositContract",props,  <HoldingDepositContractCreate/>)}
        </Grid>
    </Grid>
}


function LandlordContractStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("landlordContract",props, <LandlordContractCreate/>)}
        </Grid>
    </Grid>
}
function PayMoveInMoneyStep(props){
    const {formValue} = props;
    return <Grid container>
        If the move in money was paid, please activate tenancy.
    </Grid>
}


function ReadyStep(props){
    const {formValue} = props;
    return <Grid container>
        The tenancy is ready. It will turn active on ...
    </Grid>
}

function ActiveStep(props){
    const {formValue} = props;
    return <Grid container>
        Tenancy is active.
    </Grid>
}

function ExpiredStep(props){
    const {formValue} = props;
    return <Grid container>
        Tenancy is expired.
    </Grid>
}



