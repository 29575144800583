import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {authProvider} from "../../utils/authorizationProvider";
import {useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {routes} from "../../utils/routes";
import logo from "../../logo.svg";
import {useAuthPageStyles} from "./authPagesStyle";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function ResetPasswordRequest() {

    const location = useLocation();
    const dispatch = useDispatch();

    let url = "/";
    if(location.state){
        url = location.state.referrer;
    }

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [severity, setSeverity]  = useState("error");
    const [open, setOpen] = React.useState(false);


    const onChangeHandler = (event) => {

        const target = event.target;
        let value = target.value;
        const name = target.id;

        setEmail(value);
    }



    const classes = useAuthPageStyles();
    const onSubmitHandler = (e, value) => {

        const request = new Request(routes.resetPasswordRequest, {
            method: 'POST',
            body: JSON.stringify({email:email}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                setSeverity("success");
                setMessage("Thanks for your request. If this email exists in our database, you'll receive an email shortly with details to change your password ");
                setOpen(true);

                return response.json();
            })
            .catch((err)=>{
                setSeverity("error");
                setMessage("Error");
                setOpen(true);
            })
        //.then(dispatch(push(url)))
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt={"logo.svg"}></img>
                    <ValidatorForm className={classes.form} onSubmit={onSubmitHandler} onError={errors => console.log(errors)}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            onChange={onChangeHandler}
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                        >
                            Send change password request
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/login" variant="body2">
                                    Back to login
                                </Link>
                            </Grid>
                            <Grid item>
                            </Grid>
                        </Grid>

                    </ValidatorForm>

                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}


