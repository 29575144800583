import React from "react";
import TenantRelationshipNew from "../../pages/tenantRelationship/TenantRelationshipNew";
import TenantRelationshipView from "../../pages/tenantRelationship/TenantRelationshipView";
import TableItem from "../classes/TableItem";
import {changeTimeFormat, inputFormat, readableTimeFormat} from "../../utils/timeManager";


const  overridden_landlord_relationships = {
    table:[
        new TableItem("tenant", "Tenant", <Tenant/>),
        new TableItem("fCreatedAt", "Created at",<TimeStamp></TimeStamp>),
        new TableItem("currentPlace", "Status" ),

    ],
    "model": {
        tenant:{
            type:"embedded_single"
        }
    },
    "editPage": <TenantRelationshipView/>,
    "createPage": <TenantRelationshipNew/>
}

export default overridden_landlord_relationships;

function Tenant({record}){
    return <div>{record.personalData.name} {record.personalData.surname}</div>
}

function TimeStamp({record}){
    return (record) ? changeTimeFormat(record, "YYYY-MM-DD HH:mm:ss", readableTimeFormat): <div>No date</div>;
}
