import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider, useSelector} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {ConnectedRouter, connectRouter, routerMiddleware} from 'connected-react-router';
import {reducer as form} from 'redux-form';
import {createBrowserHistory} from 'history';
import thunk from "redux-thunk";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import ErrorBar from "./ErrorBar";
import appReducer from "react-frontend-generator/dist/redux/reducers/app/appReducer";
import {mainTheme} from "./style/themes";

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        router: connectRouter(history),
        form,
        appReducer:appReducer
        /* Add your reducers here */
    }),
    composeEnhancers(
        applyMiddleware(routerMiddleware(history),thunk)
    )
);

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}


function ThemedApp(){
    const {appReducer} = useSelector(state=>state);
    return <ThemeProvider theme={mainTheme}>
        <ErrorBoundary>
            <App />
            <ErrorBar/>
        </ErrorBoundary>
    </ThemeProvider>
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
           <ThemedApp/>
        </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
