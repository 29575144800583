import React from "react";
import Grid from "@material-ui/core/Grid";

export function Kitchen(props){
    const {model}=props
    return <Grid container>
        <Grid item xs={6} md={6}>
            {model.getInputField("diningArea", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("washingMachine",props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("tumbleDryer",props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("microwave", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("dishwasher", props)}
        </Grid>
        <Grid item xs={12} md={12}>
            {model.getInputField("images",props)}
        </Grid>

    </Grid>
}
