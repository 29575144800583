import {compareWorkflowStates, isAnyWorkflowStates} from "../../tools/workflow/workflowHelpers";
import LandlordRelationshipShow from "../showPages/LandlordRelationshipShow";
import React from "react";
import Acquisition from "../createPages/Acquisition";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function (props){
    const {record} = props;


    const isNotWorkflow = isAnyWorkflowStates(record,  [["active"], ["expired"]])

    if(record.currentPlace){
        return (isNotWorkflow) ? <LandlordRelationshipShow {...props}/> : <Acquisition {...props}/>
    }
    return <div>
        <CircularProgress />
    </div>
}