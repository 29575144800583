import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {ReferenceModel} from "react-frontend-generator/dist/resource-models/propertyModels/ReferenceModel";
import {REFERENCE} from "react-frontend-generator/dist/generators/forms/inputs/InputTypes";
import {Model} from "react-frontend-generator/dist/resource-models/Model";
import {FormValue} from "react-frontend-generator/dist/resource-models/formvalue/FormValue";
import {Errors} from "react-frontend-generator/dist/generators/errors/Errors";
import {useGetOne} from "react-frontend-generator/dist/redux/actions/verbs/get_one";
import {getFromMap} from "react-frontend-generator/dist/utils/mapUtils";
import {Record} from "react-frontend-generator/dist/resource-models/Record";
import {useSelector} from "react-redux";

export default function (props){
    const resourceName = "default_payment_items";
    const {appReducer} = useSelector(state =>state);
    const {registry} = appReducer;

    console.log("def", registry.find(item => item.name === "default_payment_items"))
    const {model, formValue, record, setFormValue} = props
    const refModel= new ReferenceModel("defaultPayment", {
        type: REFERENCE,
        label: "Default Payment",
        resourceName: resourceName,
        resource: registry.find(item => item.name === "default_payment_items").resource,
        optionText: "id",
    });


    const{id:recordID} = record;

    const localModel = new Model([refModel]);
    const [localFormValue, setLocalFormValue] = useState(new FormValue());

    useEffect(()=>{console.log("local form value", localFormValue)}, [localFormValue])
    const {getOne} = useGetOne();

    useEffect(()=>{
        if(localFormValue["defaultPayment"])
        {
            const id = localFormValue["defaultPayment"]
            getOne(resourceName, id ).then(result => {
                setFormValue(formValue.updateFormValue("unitAmount", result.fee).updateFormValue("description", result.name))
            } );
        }
    },[localFormValue])


    return <Grid container spacing={2}>
        <Grid item xs={1} md={1}>
            { (recordID ===undefined ) && localModel.getInputField("defaultPayment", {model:localModel, record:record ?? new Record(), formValue:localFormValue, setFormValue:setLocalFormValue, errors:new Errors([])})}
        </Grid>
        <Grid item xs={7} md={6}>
            {model.getInputField("description", props)}
        </Grid>
        <Grid item xs={1} md={1}>
            {model.getInputField("quantity", props)}
        </Grid>
        <Grid item xs={1} md={2}>
            {model.getInputField("unitAmount", props)}
        </Grid>
    </Grid>

}