import TableItem from "../classes/TableItem";
import React from "react";
import AreaCreate from "../../pages/area/AreaCreate";
import NeighborhoodCreate from "../../pages/neighborhood/NeighborhoodCreate";


const neighborhoodOverride = {
    table:[new TableItem("name", "Neighborhood"), new TableItem("area", "Area")],
    createPage:<NeighborhoodCreate/>,
    editPage:<NeighborhoodCreate/>,
}

export default neighborhoodOverride
