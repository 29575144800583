import {AUTH_ENTRYPOINT} from "../config/entrypoint";

export const routes = {
    authentication_token: AUTH_ENTRYPOINT + "/authentication_token",
    resetPasswordRequest: AUTH_ENTRYPOINT + "/forgot_password/",
    resetPassword: (token)=>  AUTH_ENTRYPOINT + `/forgot_password/${token}`,
    property_image_multiple_upload: "property_images/multiple-upload",
}

export function routeManipulatorWithFilters(route, filters){

    Object.keys(filters).forEach((key,index)=> {
        let suffix = (index === 0) ? "" : "&";

        if(Array.isArray(filters[key])){
            filters[key].forEach(filterValue => {
                route = route.concat(`${suffix}${key}[]=${filterValue}`)
                if(suffix===""){
                    suffix = "&";
                }
            })
        }else{
            route = route.concat(`${suffix}${key}=${filters[key]}`)
        }
    })

    return route;
}
