import SimpleCard from "../pageElements/SimpleCard";
import {showDate} from "../../utils/timeManager";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import Timeline from "@material-ui/lab/Timeline";
import React from "react";

export default function ({tenancies}){

    const timelineItems = Array.from(tenancies.values()).map((tenancy) => {

        const card = <SimpleCard elevation={2} title={`${showDate(tenancy?.tenantContract?.startDate)} - ${showDate(tenancy?.tenantContract?.endDate)}`}>
            Contenuto
        </SimpleCard>

        return <TimelineItem>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{card}</TimelineContent>
        </TimelineItem>
    })

    return <Timeline align={"right"}>
        {timelineItems.length!== 0 ? timelineItems : <div>No history for this tenancy was found</div>}
    </Timeline>
}