import React from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {MainPage} from "./components/templates/MainPage";
import SignIn from "./components/authentication/login";
import {authProvider} from "./utils/authorizationProvider";
import Dashboard from "./pages/Dashboard";

import {useSelector} from "react-redux";
import Create from "./components/routeComponents/Create";
import {useSetResourceModel} from "react-frontend-generator/dist/resource-models/modelsRegistry";
import {overrideRegistry} from "./models/formModels/allOverriddenModels";
import Edit from "./components/routeComponents/Edit";
import List, {
    LandlordRelationshipList,
    PropertiesList,
    TenantRelationshipList,
    UnitsList
} from "./components/routeComponents/List";
import Show from "./components/routeComponents/Show";
import {EnquiryList} from "./pages/enquiries/EnquiryList";
import ResetPasswordRequest from "./components/authentication/resetPasswordRequest";
import ChangePassword from "./components/authentication/changePassword";


export default function App(){

    const store = useSelector(state=>state);

    const {appReducer} = useSelector(state=>state);
    const token = localStorage.getItem('token');
    const tokenValid = authProvider.checkTokenValidity();
    const location = useLocation();

    const modelLoaded = useSetResourceModel(overrideRegistry,`${process.env.REACT_APP_AUTH_ENDPOINT}/resources`);


  const authenticatedRoutes = <Switch>
      <Route path="/login" render={() => <SignIn/>}/>
      <Route path="/reset-password" render={() => <ResetPasswordRequest/>}/>
      <Route path="/change-password/:token" render={() => <ChangePassword/>}/>
      <Route path="/properties" exact render={() => <MainPage main={<PropertiesList />}/>} />
      <Route path="/units" exact render={() => <MainPage main={<UnitsList />}/>} />
      <Route path="/enquiries" exact render={() => <MainPage main={<EnquiryList />}/>} />
      <Route path="/landlord_relationships" exact render={() => <MainPage main={<LandlordRelationshipList />}/>} />
      <Route path="/tenant_relationships" exact render={() => <MainPage main={<TenantRelationshipList />}/>} />
    <Route path="/:urlResourceName/new" exact render={() => <MainPage main={<Create />}  />}/>
    <Route path="/:urlResourceName" exact render={() => <MainPage main={<List />}/>} />
    <Route path="/:urlResourceName/:id/edit" exact render={() => <MainPage main={<Edit />} />}/>
    <Route path="/:urlResourceName/:id/show" exact render={() => <MainPage main={<Show />} />}/>
      <Route path="/" render={() => <MainPage main={<Dashboard/>} />}/>
  </Switch>

  const unauthenticatedRoutes =
      <Switch>
        <Route path="/login" render={() => <SignIn/>}/>
          <Route path="/reset-password" render={() => <ResetPasswordRequest/>}/>
          <Route path="/change-password/:token" render={() => <ChangePassword/>}/>
        <Route path="/">
          <Redirect
              to={{
                pathname: "/login",
                search: "",
                state: { referrer: location }
              }}
          />
        </Route>
      </Switch>

  return modelLoaded ?  ((tokenValid) ? authenticatedRoutes : unauthenticatedRoutes) : <div/>;
}






