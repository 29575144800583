import React from "react";
import Section from "../../components/pageElements/Section";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@material-ui/core";
import SaveButton from "../../components/buttons/SaveButton";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function PropertyCreate(props){
    const {model, loading, submitHandler}= props

    const mySubmitHandler = async ()=>{
        try{
            const response = await submitHandler()
            window.location.href = `/properties/${response.id}/edit`
        }catch(error){

        }
    }

    return <Section title={"Property Details"}>
        <Grid container spacing={2}>
            <Grid xs={6} md={6} item>
                {model.getInputField("propertyName",props)}
            </Grid>
            <Grid xs={6} md={6} item>
                {model.getInputField("type",props)}
            </Grid>
            <Divider/>
            <Grid xs={6} md={6} item>{model.getInputField("address",props)}</Grid>
            <Grid xs={6} md={6} item>{model.getInputField("city",props)}</Grid>
            <Grid xs={6} md={6} item>{model.getInputField("country",props)}</Grid>
            <Grid xs={6} md={6} item>{model.getInputField("county",props)}</Grid>
            <Grid xs={6} md={6} item>{model.getInputField("postcode",props)}</Grid>
            <Grid xs={6} md={6} item>{model.getInputField("neighborhood",props)}</Grid>
            <Grid xs={6} md={6} item>{model.getInputField("isMyrooms",props)}</Grid>
            <SaveLoaderButton loading={loading} onClick={mySubmitHandler}>Save</SaveLoaderButton>
        </Grid>
    </Section>
}

