import {useParams} from "react-router";
import React, {useMemo} from "react";
import {RouteFilterList} from "react-frontend-generator/dist/generators/pages/ListPageGenerator";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import Button from "@material-ui/core/Button";
import ButtonsHorizontalList from "../buttons/ButtonsHorizontalList";
import {getAvailableUnits} from "../../pages/tableActions/units/GetAvailableUnits";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Operation} from "react-frontend-generator/dist/generators/pages/list/listHelpers/Operation";

export default function (){
    const dispatch = useDispatch();
    const {urlResourceName} = useParams();
    const presetFilters = useMemo(()=>{return {}},[])

    const itemOperations = [
        new Operation({color:"primary", text:"edit", onClick: (row) => dispatch(push(`/${urlResourceName}/${row.id}/edit`))}),
        new Operation({color:"secondary", text:"show", onClick: (row) => dispatch(push(`/${urlResourceName}/${row.id}/show`))})
    ]

    return <>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <ButtonsHorizontalList>
                <Button color="primary" variant="contained" onClick={()=> dispatch(push(`/${urlResourceName}/new`))}>New</Button>
            </ButtonsHorizontalList>
        </div>
            <RouteFilterList resourceName={urlResourceName} filters={presetFilters} itemOperations={itemOperations}/>
        </>
}

export function PropertiesList (){
    const dispatch = useDispatch();
    const presetFilters = useMemo(()=>{return {
        currentPlace: "managed"
    }},[])

    const itemOperations = [
        new Operation({color:"primary", icon:<VisibilityIcon/> ,  text:"edit", onClick: (row) => dispatch(push(`/properties/${row.id}/edit`))})
    ]

    return <>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <ButtonsHorizontalList>
                <Button color="primary" variant="contained" onClick={()=> dispatch(push(`/properties/new`))}>New</Button>
            </ButtonsHorizontalList>
        </div>
        <RouteFilterList resourceName={"properties"} filters={presetFilters} itemOperations={itemOperations}/>
    </>
}

export function UnitsList (){
    const dispatch = useDispatch();
    const presetFilters = useMemo(()=>{return {
    }},[])

    const itemOperations = [
        new Operation({color:"primary", text:"edit", onClick: (row) => dispatch(push(`/units/${row.id}/edit`))})
    ]

    return <>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <ButtonsHorizontalList>
                <Button color="secondary" variant="contained" onClick={()=> getAvailableUnits()}>Export available units</Button>
                <Button color="primary" variant="contained" onClick={()=> dispatch(push(`/units/new`))}>New</Button>
            </ButtonsHorizontalList>

        </div>
        <RouteFilterList resourceName={"units"} filters={presetFilters} itemOperations={itemOperations}/>
    </>
}

export function LandlordRelationshipList (){
    const dispatch = useDispatch();
    const presetFilters = useMemo(()=>{return {}},[])

    const itemOperations = [
        new Operation({color:"primary", icon:<VisibilityIcon/> ,  text:"edit", onClick: (row) => dispatch(push(`/landlord_relationships/${row.id}/edit`))})
    ]

    return <>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <ButtonsHorizontalList>
                <Button color="primary" variant="contained" onClick={()=> dispatch(push(`/landlord_relationships/new`))}>New</Button>
            </ButtonsHorizontalList>
        </div>
        <RouteFilterList resourceName={"landlord_relationships"} filters={presetFilters} itemOperations={itemOperations}/>
    </>
}


export function TenantRelationshipList (){
    const dispatch = useDispatch();
    const presetFilters = useMemo(()=>{return {}},[])

    const itemOperations = [
        new Operation({color:"primary",  text:"edit", onClick: (row) => dispatch(push(`/tenant_relationships/${row.id}/edit`))})
    ]

    return <>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <ButtonsHorizontalList>
                <Button color="primary" variant="contained" onClick={()=> dispatch(push(`/tenant_relationships/new`))}>New</Button>
            </ButtonsHorizontalList>
        </div>
        <RouteFilterList resourceName={"tenant_relationships"} filters={presetFilters} itemOperations={itemOperations}/>
    </>
}
