import React, {useEffect, useState} from "react";
import {TransitionModal} from "./TransitionDialog";
import ContractWorkflow from "../../ContractWorkflow";
import TFRContract from "../tfrContract/TFRContract";
import {EditPage} from "react-frontend-generator/dist/generators/pages/EditPageGenerator";

export default function ({resourceName, id, onClose, refresh}){
    const [open, setOpen] = useState(true)
    const onCloseHandler = () => {
        onClose()
        setOpen(!open)
    }
    const [onClick, setOnClick] = useState(()=>console.log("selected"));


    return <TransitionModal open={open} handleClose={onCloseHandler} onClick={onClick}>
        <EditPage propResourceName={resourceName} propId={id} propEditPage={<RelocationForm refresh={refresh} setOnClick={setOnClick}></RelocationForm>}/>
    </TransitionModal>
}

function RelocationForm(props){
    const {model, record, formValue, partialSubmitHandler, setOnClick} = props

    useEffect(()=>{
        setOnClick( ()=> ()=> partialSubmitHandler(formValue.updateFormValue("requestedTransition", "relocation")))
    },[formValue])

    return model.getInputField("tfrContract", props, <ContractWorkflow uploadData={<TFRContract/>} {...props}/>)

}