import React from "react";
import Section from "../../components/pageElements/Section";
import Grid from "@material-ui/core/Grid";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function (props){
    const {model, submitHandler, loading} = props

    const mySubmitHandler = async ()=>{
        try{
            const response = await submitHandler()
            window.location.href = `/units/${response.id}/edit`
        }catch(error){

        }

    }


    return <div>
        <Section title={"Unit Details"}>
            <Grid container spacing={2}>
                <Grid xs={12} md={6} item>
                    {model.getInputField("unitLetter", props)}
                </Grid>
                <Grid xs={12} md={6} item>
                    {model.getInputField("type", props)}
                </Grid>
                <Grid xs={12} md={6} item>
                    {model.getInputField("property", props)}
                </Grid>
                <Grid xs={12} md={6} item>
                    {model.getInputField("availableFrom", props)}
                </Grid>
                <Grid xs={12} md={6} item>
                    {model.getInputField("weeklyPrice", props)}
                </Grid>

                <Grid xs={12} md={12}>
                    <SaveLoaderButton loading={loading} onClick={mySubmitHandler}>Save</SaveLoaderButton>
                </Grid>
            </Grid>
        </Section>
    </div>
}
