import Grid from "@material-ui/core/Grid";
import React from "react";
import SaveButton from "../../components/buttons/SaveButton";

export default function (props){
    const {model, submitHandler} = props
    return <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            {model.getInputField("title", props)}
        </Grid>
        <Grid item xs={12} md={12}>
            {model.getInputField("description", props)}
        </Grid>
        <SaveButton onClick={submitHandler}>Save</SaveButton>
    </Grid>
}