import React from "react";
import Grid from "@material-ui/core/Grid";

export function SharedBathroom(props){
    const {model}=props
    return <Grid container>
        <Grid item xs={6} md={6}>
            {model.getInputField("bathOrShower", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("mirror", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("bathCurtain",props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("window", props)}
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getInputField("jacuzzi",props)}
        </Grid>
        <Grid item xs={12} md={12}>
            {model.getInputField("images", props)}
        </Grid>

    </Grid>
}
