import Grid from "@material-ui/core/Grid";
import React from "react";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function (props){
    const {model, loading, submitHandler} = props;

    const mySubmitHandler = async ()=>{
        try{
            const response = await submitHandler()
            window.location.href = `/postcodes`
        }catch(error){

        }
    }

    return <Grid container spacing={2}>
        <Grid item xs={6}>
            {model.getInputField("postcode", props)}
        </Grid>
        <Grid item xs={6}>
            {model.getInputField("city", props)}
        </Grid>
        <SaveLoaderButton loading={loading} onClick={mySubmitHandler}>Save</SaveLoaderButton>
    </Grid>
}
