import React from "react";
import PropertyShow from "../../pages/showPages/PropertyShow";
import PropertyCreate from "../../pages/createPages/PropertyCreate";

const propertyOverride =   {
     "model":{
        "propertyName":{"xs":12,"md":6, group:"PropertyInfo", position:1, listShow:true, label:"Property Name"},
        "askedPrice":{type:"money"},
        "currentPlace":{listShow:true, "label":"State"}
     },
   "table":[{id:"propertyName", label:"Property Name"}, {id:"address", label:"Address"}, {id:"arthurProperty.id", label:"Arthur Id", viewElement:<ArthurPropertyLink/>}, {id:"currentPlace", label:"Status"}],
   "editPage":<PropertyShow/>,
   "showPage": <PropertyShow/>,
    "createPage":<PropertyCreate/>,
   "formType":"tab-horizontal"
}

function ArthurPropertyLink(props){
    const {record} = props;
    if(typeof record =="string"){
        return <a target="_blank" href={`https://system.arthuronline.co.uk/myrooms1/properties/view/${record}`}>{record}</a>
    }else{
        return "";
    }

}


export default propertyOverride;

