import LandlordTenancyPaymentCreate from "../../pages/createPages/LandlordTenancyPaymentCreate";
import TableItem from "../classes/TableItem";
import LandlordTenancyPaymentShow from "../../pages/showPages/LandlordTenancyPaymentShow";
import {yellow} from "@material-ui/core/colors";
import green from "@material-ui/core/colors/green";

const landlordTenancyPaymentOverride = {
    model:{
      status:{
          colorMap: {
              2:{
                  backgroundColor:yellow.A200,
                  color:"black",
              },
              3:{
                  backgroundColor:green.A100,
                  color:"white"
          }}
      }
    },
    table: [new TableItem("dueDate", "Due date"), new TableItem("status", "Status"), new TableItem("type", "Type")],
    "createPage": <LandlordTenancyPaymentCreate />,
    "editPage": <LandlordTenancyPaymentCreate />,
    "showPage": <LandlordTenancyPaymentShow/>
}

export default landlordTenancyPaymentOverride