import React from "react";
import WorkflowPageVertical from "../WorkflowPageVertical";
import {WorkflowStep} from "../../tools/workflow/workflowHelpers";
import Transition from "../../models/classes/Transition";
import {TenantBasicDataStep} from "./workflowSteps/TenantBasicDataStep";
import {ChooseDepositTypeStep, ChooseReferencingStep, PayMoveInStep} from "./workflowSteps/TenantCompleteIfrStep";
import {TenantUploadDocumentsStep} from "./workflowSteps/TenantUploadDocumentsStep";
import TenantFullAgreementStep from "./workflowSteps/TenantFullAgreementStep";
import {TenantHoldingDepositStep} from "./workflowSteps/TenantHoldingDepositStep";
import Grid from "@material-ui/core/Grid";
import {Card, Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

const CREATED = "created";
const BASIC_DETAILS_SET = "basic_details_set";
const IFR_COMPLETED = "ifr_completed";
const ID_DOCUMENTS_UPLOADED = "id_documents_uploaded";
const HOLDING_DEPOSIT_PAID = "holding_deposit_paid";
const REFERENCE_SELECTED = "reference_selected";
const DEPOSIT_TYPE_CHOSEN = "deposit_type_chosen";
const MOVE_IN_MONEY_PAID = "move_in_money_paid";
const FULL_AGREEMENT_COMPLETED = "full_agreement_completed";
const ACTIVE = "active";
const EXPIRED = "expired";
const UNDER_RENEWAL = "under_renewal";
const EXPIRING = "expiring";
const ENDING = "ending";
const PAST = "past";

const stepRegistry = [
    new WorkflowStep([CREATED], (props)=><TenantHoldingDepositStep {...props}/>,"Holding deposit", [new Transition("pay_holding_deposit", "Confirm")] ),
    new WorkflowStep([HOLDING_DEPOSIT_PAID], (props)=><ChooseReferencingStep {...props}/>,"Referencing", [new Transition("referencing", "Choose referencing")] ),
    new WorkflowStep([REFERENCE_SELECTED], (props)=><ChooseDepositTypeStep {...props}/>,"Choose deposit type", [new Transition("choose_deposit_type", "Confirm")] ),
    new WorkflowStep([DEPOSIT_TYPE_CHOSEN], (props)=><PayMoveInStep {...props}/>,"Move in money", [] ),
    new WorkflowStep([MOVE_IN_MONEY_PAID], (props)=><div>Everything done.</div>,"Onboarding successful", [] ),
    /*new WorkflowStep([IFR_COMPLETED], (props)=><TenantUploadDocumentsStep {...props}/>,"Upload documents", [new Transition("upload_id_documents", "Confirm")] ),
    new WorkflowStep([ID_DOCUMENTS_UPLOADED], (props)=><TenantFullAgreementStep {...props}/>,"Full Agreement", [new Transition("insert_basic", "Confirm")] ),*/

    /*new WorkflowStep(BASIC_DETAILS_SET, (props)=><LandlordContractStep {...props}/>,"Landlord Contract" ),
    new WorkflowStep(IRC_COMPLETED, (props)=><PayMoveInMoneyStep {...props}/>,"Move in money" ,[new Transition("turn_to_ready", "activate")]),
    new WorkflowStep(ID_DOCUMENTS_UPLOADED, (props)=><ReadyStep {...props}/> ),
    new WorkflowStep(FULL_AGREEMENT_COMPLETED, (props)=><ActiveStep {...props}/> ),*/
]

export default function (props){
    const {model} = props
    return <Grid container spacing={2}>
        <Grid item xs={6}>
            <Card sx={{ maxWidth: 345 }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Tenant details
                        </Typography>
                        <TenantBasicDataStep {...props}/>
                    </CardContent>
            </Card>
        </Grid>
        <Grid item xs={6}>
            <WorkflowPageVertical stepRegistry={stepRegistry} standardProps={props} single={false}/>
        </Grid>

    </Grid>
}

