import Grid from "@material-ui/core/Grid";
import React from "react";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function (props){
    const {model, loading, submitHandler} = props

    return <Grid container spacing={2}>
        <Grid xs={12} md={12} item>{model.getInputField("title", props)} </Grid>
        <Grid xs={12} md={12} item>{model.getInputField("description", props)} </Grid>
        <Grid xs={12} md={12} item>{model.getInputField("view360", props)} </Grid>
        <Grid xs={12} md={12} item>{model.getInputField("video", props)} </Grid>
        <Grid xs={12} md={12} item>{model.getInputField("studentsOnly", props )}</Grid>

        <Grid xs={12} md={6} item>{model.getInputField("availableToLet", props )} </Grid>
        <SaveLoaderButton loading={loading} onClick={submitHandler}>Save</SaveLoaderButton>
        {/*<Grid xs={12} md={12} item>{model.getInputField("portals", props)}</Grid>*/}
    </Grid>

}
