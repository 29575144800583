import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {FormValue} from "react-frontend-generator/dist/resource-models/formvalue/FormValue";
import {Create} from "react-frontend-generator/dist/generators/pages/CreatePageGenerator";

export default function CreateDeduction(props){

    const {model, formValue, record, partialSubmitHandler} = props
    const [open, setOpen] = useState(false)
    const {firstLandlordTenancy} = record
    const {id} = firstLandlordTenancy



    const [formValueDeductions, setFormValueDeductions] = useState(FormValue.createFromRecord(record, model))

    const save = () => {
        partialSubmitHandler(formValueDeductions);
    }

    const lockedDeductions = new FormValue();
    lockedDeductions["landlordTenancy"] = id;

    return <>
        <Button onClick={()=>setOpen(!open)} variant={"contained"} color={"primary"}>Deduction</Button>
        <SimpleModal open={open} handleClose={()=>setOpen(false)} actions={<Button onClick={save} >Save deductions</Button>}>
            <Grid container>
                <Grid item md={12} xs={12}>
                    {model.getOutputField("firstLandlordTenancy.deductions", props)}
                </Grid>
                <Grid item md={12} xs={12}>
                    <Create propResourceName={"deductions"} lockedFormValue={lockedDeductions}/>
                </Grid>

            </Grid>
        </SimpleModal>
    </>

}

const useDialogStyle = makeStyles((theme)=> ({
    root:{
        minWidth: "40vw"
    }
}))
function SimpleModal({open, handleClose,children,actions}) {

    // getModalStyle is not a pure function, we roll the style only on the first render;

    const classes = useDialogStyle();


    return (
        <Dialog className={classes.root} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Create new</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    );
}