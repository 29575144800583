import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from '@material-ui/icons/Home';
export default function ({unit}){
    const {id,title, type, roomName, searchKey} = unit;
    return <>

        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <HomeIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Link color="secondary" href={`/${searchKey}/${id}/edit`}>{roomName}</Link>} secondary={title} />
        </ListItem>
        <ListItem>

    </ListItem>
    </>
}