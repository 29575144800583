import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import {Fade} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function ({actions}){
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const actionMenuItems = actions.map(({onClick, text, disabled}) => <MenuItem disabled={disabled} onClick={onClick}>{text}</MenuItem>)

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button endIcon={(anchorEl === null) ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>} variant={"contained"} color={"primary"} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                Actions
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                variant={"selectedMenu"}
                TransitionComponent={Fade}
                anchorPosition={{ left: 100, top: 1000 }}
            >
                {actionMenuItems}
            </Menu>
        </div>
    );
}