import Col2Layout from "../../../components/templates/Col2Layout";
import React from "react";
import Grid from "@material-ui/core/Grid";
import OutlinedCard from "../../../components/pageElements/Card";
import TenancyDetail from "../TenancyDetail";
import {Typography} from "@material-ui/core";

export default function ActiveTenancyDetail(props){
    const {model} = props



    return <Col2Layout right={<RightPanel/>} left={<LeftPanel {...props}/>}>
    </Col2Layout>


}

function LeftPanel(props){
    const {model, record} = props
    const nextTenancy = record?.nextTenancy;

    const activeTenancyTitle =<Grid container spacing={1}>
        <Grid item xs={6} md={6}>
            <Typography>Active Tenancy </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getOutputField("activeTenancy.tenancystatus", props, undefined, false)}
        </Grid>
    </Grid>

    const nextTenancyTitle = nextTenancy ? <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
            <Typography>Next Tenancy </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            {model.getOutputField("nextTenancy.tenancystatus", props, undefined, false)}
        </Grid>
    </Grid> : <div>No</div>

    return <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            <OutlinedCard title={activeTenancyTitle}>
                {model.getInputField("activeTenancy", props, <TenancyDetail></TenancyDetail>)}
            </OutlinedCard>
        </Grid>
        {nextTenancy &&
        <Grid item xs={12} md={12}>
            <OutlinedCard title={nextTenancyTitle}>
            {nextTenancy && model.getInputField("nextTenancy", props, <TenancyDetail></TenancyDetail>)}
            </OutlinedCard>
        </Grid>
        }
        <Grid item xs={12} md={12}>
            <OutlinedCard title={"Internal Notes"}>
                To be developed
            </OutlinedCard>
        </Grid>
    </Grid>

}

function RightPanel(){
    return <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            <OutlinedCard title="Messages">To be developed</OutlinedCard>
        </Grid>
        <Grid item xs={12} md={12}>
            <OutlinedCard title="Notifications">To be developed</OutlinedCard>
        </Grid>
        <Grid item xs={12} md={12}>
            <OutlinedCard title="Favourite reports">To be developed</OutlinedCard>
        </Grid>
    </Grid>
}
