import React from 'react';
import Grid from "@material-ui/core/Grid";
import WorkflowPageVertical from "../WorkflowPageVertical";
import Transition from "../../models/classes/Transition";
import NegotiationCreate from "./NegotiationCreate";
import PropertyEnquiryCreate from "./PropertyEnquiryCreate";
import LandlordCreate from "./LandlordCreate";
import FirstLandlordTenancyCreate from "./FirstLandlordTenancyCreate";


const stepRegistry = [
    {id:["created"], nextTransitions:[new Transition("input_basic_details", "Create")], label:"Enter property and Landlord", showComponent:(props)=><CreationStep record={props.formValue} {...props}/>, editComponent:(props)=><CreationStep {...props}/>},
    {id: ["basic_details_set"], nextTransitions:[new Transition("pre_approve", "Approve"), new Transition("pre_reject", "Reject")], label:"Pre approvation by manager", showComponent:(props)=><PreApprovationStep record={props.formValue} {...props}/>, editComponent:(props)=><PreApprovationStep {...props}/>},
    {id: ["pre_approved"], nextTransitions:[], label:"Negotiation", showComponent:(props)=><StartNegotiationStep record={props.formValue} {...props}/>, editComponent:(props)=><StartNegotiationStep {...props}/>},
    {id: ["negotiation_approved"], nextTransitions:[new Transition("post_approve", "Approve"), new Transition("reject", "Reject")], label:"Post approvation by manager required", showComponent:(props)=><PostApprovation record={props.formValue} {...props}/>, editComponent:(props)=><PostApprovation {...props}/>},
    {id: ["post_approved"], nextTransitions:[], label:"Tenancy", showComponent:(props)=><HoldingContractStep record={props.formValue} {...props}/>, editComponent:(props)=><HoldingContractStep {...props}/>},
    {id: ["approved"], nextTransitions:[], label:"Acquisition Done", showComponent:(props)=><ApprovedStep record={props.formValue} {...props}/>, editComponent:(props)=><ApprovedStep {...props}/>}
]


export default function Acquisition(props){
    return <WorkflowPageVertical stepRegistry={stepRegistry} standardProps={props} single={false}/>
}

function CreationStep(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
            {model.getInputField("property",props, <PropertyEnquiryCreate/>)}
        </Grid>
        <Grid item md={6} xs={12}>
            {model.getInputField("landlord", props,<LandlordCreate/>)}
        </Grid>
    </Grid>
}

function PreApprovationStep(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
            {model.getInputField("property", props, <PropertyEnquiryCreate/>)}
        </Grid>
        <Grid item md={6} xs={12}>
            {model.getInputField("landlord", props, <LandlordCreate/> )}
        </Grid>
    </Grid>
}

function StartNegotiationStep(props){
    const {model} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            {model.getInputField("negotiation", props,<NegotiationCreate/>)}
        </Grid>
    </Grid>
}

function PostApprovation(props){
    const {formValue} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            Approve it or reject it
        </Grid>
    </Grid>
}

function HoldingContractStep(props){
    const {formValue, model} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            { model.getInputField("firstLandlordTenancy", props, <FirstLandlordTenancyCreate/>)}
        </Grid>
    </Grid>
}


function ApprovedStep(props){
    return <Grid container>
        <Grid item md={12} xs={12}>
            First tenancy approved
        </Grid>
    </Grid>
}



