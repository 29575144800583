import Grid from "@material-ui/core/Grid";
import React from "react";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function UnitEdit(props){
    const {model, submitHandler, loading} = props


    return <Grid container spacing={2}>
        <Grid xs={12} md={6} item>
            {model.getInputField("unitLetter", props)}
        </Grid>
        <Grid xs={12} md={6} item>
            {model.getInputField("type", props)}
        </Grid>
        <Grid xs={12} md={6} item>
            {model.getInputField("plan", props)}
        </Grid>
        <Grid xs={12} md={6} item>
            {model.getInputField("property", props)}
        </Grid>
        <Grid xs={12} md={6} item>
            {model.getInputField("availableFrom", props)}
        </Grid>
        <Grid xs={12} md={6} item>
            {model.getInputField("weeklyPrice", props)}
        </Grid>
        <Grid xs={12} md={12} item>
            {model.getInputField("images", props)}
        </Grid>
        <Grid xs={12} md={12} item>
            {model.getInputField("features", props,<UnitFeatures></UnitFeatures> )}
        </Grid>
        <Grid xs={12} md={12}>
            <SaveLoaderButton loading={loading} onClick={submitHandler}>Save</SaveLoaderButton>
        </Grid>
    </Grid>
}

function UnitFeatures(props){
    const {model}= props
    return <Grid container spacing={2}>
        <Grid item xs={9} md={9}>
            {model.getInputField("feature",props)}
        </Grid>
        <Grid item xs={3} md={3}>
            {model.getInputField("amount",props)}
        </Grid>
    </Grid>
}
