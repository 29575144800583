import Paper from "@material-ui/core/Paper";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import TabberGenerator from "../../../components/tabs/TabberGenerator";
import {getFromMap} from "react-frontend-generator/dist/utils/mapUtils";
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuite';
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import UnitEdit from "../../editPages/UnitEdit";
import SimpleCard from "../../../components/pageElements/SimpleCard";
import {Typography} from "@material-ui/core";
import UnitMarketingEdit from "../../editPages/UnitMarketingEdit";


const useStyles = makeStyles((theme) => ({
    column: {
        flexBasis: '33.33%',
        padding:10
    },
    lastColumn: {
        flexBasis: '33.33%',
        padding:10, justifyContent:"flex-end"
    },
    paper:{
        display:"flex",
        backgroundColor: "#f5f5f5",
        padding: "10px"
    }
}));
export default function UnitShow(props){
    const dispatch = useDispatch();
    const classes = useStyles();
    const {model, record} = props;
    const {unitLetter, id, title, property} = record
    const subTitle = model.getOutputField("type", props, undefined, false)

    const tabs = [
        {"label":"Unit", component:<UnitEdit {...props}/>},
        {"label":"Marketing", component:<UnitMarketingEdit {...props}/>}
    ]

    return<>
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <SimpleCard transparent title={`Room ${unitLetter}`} subTitle={subTitle} avatar={<AirlineSeatIndividualSuiteIcon/>}>
                        <Grid item xs={12} md={12}>
                            <Typography>{title}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography>Property: <a href={`/properties/${property?.id}/edit`}>{property?.propertyName}</a></Typography>
                        </Grid>
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleCard transparent title={"Unit balance"} avatar={<MonetizationOnIcon/>}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                {model.getOutputField("weeklyPrice", props)}
                            </Grid>
                        </Grid>
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleCard transparent title={"Information"} avatar={<MonetizationOnIcon/>}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                {model.getOutputField("currentPlace", props, undefined, false)}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {model.getOutputField("availableFrom", props)}
                            </Grid>
                        </Grid>
                    </SimpleCard>
                </Grid>
            </Grid>
        </Paper>
        <TabberGenerator tabs={tabs}/>
        </>
}
