import Grid from "@material-ui/core/Grid";
import React from "react";

export function TenantBasicDataStep(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getInputField("tenant", props, <TenantDetails/>)}
        </Grid>
    </Grid>

}

function TenantDetails(props){
    const {model} = props;
    return <Grid container spacing={2}>
        <Grid md={12} xs={12} item>
        </Grid>
        <Grid md={12} xs={12} item>
            {model.getInputField("personalData",props)}
        </Grid>
    </Grid>
}