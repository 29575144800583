import Grid from "@material-ui/core/Grid";
import React from "react";
import {IconButton} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from "@material-ui/core/Button";
export function TenantHoldingDepositStep(props){
    const {model, record} = props;
    const tenant = record.tenant.personalData;
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            The unique payment url has already been sent to {tenant.name} {tenant.surname} at the email address <a href={`mailto:${tenant.email}`}>{tenant.email}</a>
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getOutputField("holdingDepositPaymentUrl", props, <HoldingDepositUrl></HoldingDepositUrl>)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getOutputField("holdingDepositPaid", props, <HoldingDepositShow></HoldingDepositShow>)}
        </Grid>
    </Grid>

}

function HoldingDepositShow({record}){

    return <div>
        Status of the payment: {(record) ? "Holding deposit paid" : "Holding deposit not paid"}
    </div>
}

function HoldingDepositUrl(props){
    return <Grid container>
        <Grid item xs={6}>
            <CopyableLink link={props.record}/>
        </Grid>
    </Grid>
}

export function CopyableLink({link}){
    return <Grid container>
        <Grid xs={10} item>
            <a href={link}>{"Payment link"}</a>
        </Grid>
        <Grid xs={2} item><Button onClick={()=>{navigator.clipboard.writeText(link)}} variant={"contained"} color={"primary"}>Copy link</Button></Grid>
    </Grid>
}
