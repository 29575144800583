import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {TabPanel} from "./TabPanel";

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function TabsWrappedLabel({tabs}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabHeaders = tabs.map((tab, index) => <Tab value={index} label={tab.label} wrapped {...a11yProps(index)} />)
    const tabPanels  = tabs.map((tab, index) => (value) => <TabPanel value={value} index={index}>{tab.component}</TabPanel>)


    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                    {tabHeaders}
                </Tabs>
            </AppBar>
            {tabPanels.map(tabPanelFunction => tabPanelFunction(value))}
        </div>
    );
}
