import _ from 'lodash';
import React from "react";

export function compareWorkflowStates(record, values){
    const keys = (record.currentPlace) ? Object.keys(record.currentPlace) : [];
    return _.difference(keys, values).length === 0 && _.difference(values, keys).length ===0;
}

export function isAnyWorkflowStates(record, /**/){
    const values = Array.from(arguments).pop();
    return values.some((item) => compareWorkflowStates(record, item))
}

export function compareStateMachineStates(record, value){
    return record.currentPlace === value;
}

export function isAnyStateMachineStates(record, /**/){
    const values = Array.from(arguments).slice(1);
    return values.some(item => record.currentPlace == item)
}





export function hasWorkflowState(record, value){
    const currentPlaceKeys = (record.currentPlace) ? Object.keys(record.currentPlace) : [];
    console.log("current place keys", currentPlaceKeys)
    const values = Array.isArray(value) ? value : [value];
    return values.reduce((accumulator, value)=> accumulator*currentPlaceKeys.includes(value), true);
}

export function WorkflowStep(id, editComponent, label = "", nextTransitions = [], showComponent = editComponent, previousTransitions = []){
    return {
        id: id,
        editComponent: editComponent,
        showComponent: showComponent,
        nextTransitions: nextTransitions,
        previousTransitions: previousTransitions,
        label:label
    }
}
