import React from "react";
import Grid from "@material-ui/core/Grid";
import TabberGenerator from "../../components/tabs/TabberGenerator";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import ApartmentIcon from '@material-ui/icons/Apartment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PropertyUnitShow from "./unit/PropertyUnitShow";
import Paper from "@material-ui/core/Paper";
import PropertyEdit from "../property/PropertyEdit";
import SimpleCard from "../../components/pageElements/SimpleCard";
import {GenericList} from "react-frontend-generator/dist/generators/pages/list/List";
import PropertyMarketingEdit from "../property/PropertyMarketingEdit";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    column: {
        flexBasis: '33.33%',
        padding:10
    },
    lastColumn: {
        flexBasis: '33.33%',
        padding:10, justifyContent:"flex-end"
    },
    paper:{
        display:"flex",
        backgroundColor: "#f5f5f5",
        padding: "10px"
    }
}));
export default function (props){
    const dispatch = useDispatch();
    const {record, model} = props;
    const {id} =(record);
    const goToEdit = ()=> dispatch(push(`/properties/${id}/edit`))


    const tabs= [
        {label:"Property",
            component:<PropertyEdit {...props} />},
        {label:"Units", component:<PropertyUnitSection{...props}/> },
        {label:"Marketing", component: <Grid container>
                {<Grid item xs={12} md={12}>
                    <PropertyMarketingEdit {...props}/>
                </Grid>}
            </Grid> }
        ];

    return<>
        <Paper>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <SimpleCard transparent  avatar={<ApartmentIcon/>} title={props.record["propertyName"]} elevation={0}>
                        {model.getOutputField("address", props,undefined, false)}
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleCard avatar={<MonetizationOnIcon/>} transparent title={"Property balance"} elevation={0}>
                        No information here yet
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} md={4}>
                </Grid>
            </Grid>
        </Paper>
        <TabberGenerator tabs={tabs}/>
    </>

}

function PropertyUnitSection(props){
    const {model} = props
    return <Grid container>
            <Grid item xs={12} md={12}>
                {model.getOutputField("units", props, <PropertyUnitShow parentRecord={props.record}/>)}
            </Grid>
    </Grid>

}
