import {changeResource} from "../actions/appActions";
import {push} from "connected-react-router";
import {useDispatch, useSelector} from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useEffect, useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/styles";
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EventIcon from '@material-ui/icons/Event';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import _ from 'lodash';


export function Navbar() {

    const dispatch = useDispatch();
    const state = useSelector(state=>state);


    const [navbarObjects, setNavbarObjects] = useState([
        /*new NavbarItem(<DashboardIcon/>, "Dashboard", '/', (link) => {dispatch(push(link))}),
        new NavbarItem(<ApartmentIcon/>, "Contacts", "people", undefined, [
            new NavbarItem(<ApartmentIcon />, "Contractors", `/agents`, (link) => {dispatch(changeResource("agents")); dispatch(push(link))}),
            new NavbarItem(<ApartmentIcon />, "Landlords", `/landlords`, (link) => {dispatch(changeResource(`landlords`)); dispatch(push(link))}),
        ]),*/
        new NavbarItem(<ApartmentIcon/>, "Property Management", "pm", undefined, [
            new NavbarItem(<ApartmentIcon/>, "Properties", `/properties`, (link) => {dispatch(changeResource(`properties`)); dispatch(push(link))}, undefined),
            new NavbarItem(<HomeIcon/>, "Units", `/units`, (link) => {dispatch(changeResource(`units`)); dispatch(push(link))}, undefined)
            /*new NavbarItem(<HomeIcon/>, "Landlord tenancies", `/landlord_relationships`, (link) => {dispatch(changeResource(`landlord_relationships`)); dispatch(push(link))}, undefined)*/
        ]),
        new NavbarItem(<ApartmentIcon/>, "Lettings", "tenancies", undefined, [
            new NavbarItem(<HomeIcon/>, "Tenants", `/tenants`, (link) => {dispatch(changeResource(`tenants`)); dispatch(push(link))}, undefined),
            new NavbarItem(<HomeIcon/>, "Enquiries", `/enquiries`, (link) => {dispatch(changeResource(`enquiries`)); dispatch(push(link))}, undefined),
            new NavbarItem(<HomeIcon/>, "Tenancies", `/tenant_relationships`, (link) => {dispatch(changeResource(`tenant_relationships`)); dispatch(push(link))}, undefined),
            /*new NavbarItem(<HomeIcon/>, "Rent collection", `/${landlordTenancy.resourceName}`, (link) => {dispatch(changeResource(`${landlordTenancy.resourceName}`)); dispatch(push(link))}, undefined),
            new NavbarItem(<HomeIcon/>, "Marketing", `/${landlordTenancy.resourceName}`, (link) => {dispatch(changeResource(`${landlordTenancy.resourceName}`)); dispatch(push(link))}, undefined),*/



        ]),
        /*new NavbarItem(<MonetizationOnIcon/>, "Marketing", '/portals', (link) => {dispatch(push(link))}),
        new NavbarItem(<EventIcon/>, "Calendar", 'events', () => console.log("coming soon...")),
        new NavbarItem(<QuestionAnswerIcon/>, "Tickets", 'tickets', () => console.log("coming soon...")),
        new NavbarItem(<ApartmentIcon/>, "Financials", "financials", undefined, [
/!*            new NavbarItem(null, "Bank Accounts", `/${bankAccount.resourceName}`, (link) => {dispatch(changeResource(`${bankAccount.resourceName}`)); dispatch(push(link))}, undefined),*!/

            new NavbarItem(null, "Default Payment Items", `/default_payment_items`, (link) => {dispatch(changeResource(`default_payment_items`)); dispatch(push(link))}, undefined)
        ]),*/
    ])

    useEffect(() => {
        const link = state.router.location.pathname;

        const checkItem = (item, link) => {
            return item.getLink() !== "/" && _.startsWith( link, item.getLink())
        }

        const findActive = function (navbarItems, link) {
            navbarItems.forEach(item => {
                item.setActive(checkItem(item, link))
                const childList = item.getChildList();
                if (childList){
                    findActive(childList, link);
                    item.expanded = childList.some(item => item.active ===true || item.expanded===true);
                }
            });
        };

        const newNavbarObjects = _.cloneDeep(navbarObjects);
        findActive(newNavbarObjects, link);

        setNavbarObjects(newNavbarObjects);
    }, [state.router.location.pathname])


    return ListGenerator(navbarObjects, "");

};


function ListGenerator(list, className){

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }));
    const classes = useStyles();
    return list.map(({text,onClick, childList, icon, active, expanded}, index) => {
        if(childList){
            return <ListElementWithChildList  expanded={expanded} text={text} index={index} className={className} icon={icon} childList={childList} classes={classes} selected={active} key={index}/>
        }else{
            return (
                <ListItem className={(className ? className : "")} button key={text} onClick={onClick} selected={active}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
            )
        }

    })
}



function ListElementWithChildList({index, className, text, icon,childList, classes, selected, expanded}){
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        if(expanded) setOpen(true);
    },[expanded])

    return <React.Fragment key={index}>
        <ListItem className={(className ? className : "")} button key={text} onClick={()=>setOpen(!open)} selected={selected}>
            <ListItemIcon> {open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
            <ListItemText primary={text} />
        </ListItem>
        <CollapsedListElement open={open} childList={childList} classes={classes}/>

    </React.Fragment>

}

function CollapsedListElement({childList, open, classes}){

    return <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            {ListGenerator(childList, classes.nested)}
        </List>
    </Collapse>
}

class NavbarItem {
    constructor(icon, text, link, onClick, childList = undefined) {
        this.icon = icon;
        this.text = text;
        this.link = link;
        this.onClick = () => onClick(link);
        this.childList = childList;
        this.active = false;
        this.expanded = false;
    }

    getLink = function() {
        return this.link;
    }

    setActive = function(active) {
        this.active = active;
    }

    getChildList = function() {
        return this.childList;
    }

    toJson() {
        const childListToSend = (this.childList) ? this.childList.map(child => child.toJson()) : (this.childList);
        return {icon: this.icon, text: this.text, link: this.link, onClick: this.onClick, childList: childListToSend, active:this.active };
    }

}
