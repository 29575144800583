import React from "react";
import Grid from "@material-ui/core/Grid";
import WorkflowGeneric from "../WorkflowGeneric";


const stepRegistry = [
    {id: "created", nextTransitions:[{value:"send_offer", label:"Send offer"}], label:"Choose a property", showComponent:(props)=><FirstStep record={props.formValue} {...props}/>, editComponent:(props)=><FirstStep {...props}/>},
    {id: "waiting_for_response", nextTransitions:[{value:"mark_as_accepted", label:"Offer accepted"}, {value:"mark_as_discarded", label:"Offer discarded"}], label:"", showComponent:(props)=><SecondStep record={props.formValue} {...props}/>, editComponent:(props)=><SecondStep {...props}/>},
    {id: "accepted", nextTransitions:[], label:"", showComponent:(props)=><AcceptedStep record={props.formValue} {...props}/>, editComponent:(props)=><AcceptedStep {...props}/>},
    {id: "discarded", nextTransitions:[], label:"", showComponent:(props)=><DiscardedStep record={props.formValue} {...props}/>, editComponent:(props)=><DiscardedStep {...props}/>}

]

export default function NegotiationOfferCreate(props){
    return <WorkflowGeneric isLinear={false} standardProps={props} stepRegistry={stepRegistry} embedded/>
}

function FirstStep(props){
    const {formValue, partialSubmitHandler, model} = props;
    const isNegotiationOfferNotSent = formValue.currentPlace === undefined || formValue.currentPlace ==="created";
    return <Grid container>
        <Grid item md={12} xs={12}>
            {isNegotiationOfferNotSent ?  model.getInputField("offer", props)  : model.getOutputField("offer", props)}
        </Grid>
    </Grid>
}

function SecondStep(props){
    const {formValue, model} = props;
    const isNegotiationOfferNotSent = formValue.currentPlace === undefined || formValue.currentPlace ==="created";
    return <Grid container>
        <Grid item md={12} xs={12}>
            {isNegotiationOfferNotSent ?  model.getInputField("offer", props) : model.getOutputField("offer", props)}
        </Grid>
    </Grid>
}
function AcceptedStep(props){
    const {formValue, model} = props;
    const isNegotiationOfferNotSent = formValue.currentPlace === undefined || formValue.currentPlace ==="created";
    return <Grid container>
        <Grid item md={8} xs={8}>
            {isNegotiationOfferNotSent ?  model.getInputField("offer", props) : model.getOutputField("offer", props)}
        </Grid>
        <Grid item md={4} xs={4}>
            Accepted
        </Grid>
    </Grid>
}

function DiscardedStep(props){
    const {formValue, model} = props;
    const isNegotiationOfferNotSent = formValue.currentPlace === undefined || formValue.currentPlace ==="created";
    return <Grid container>
        <Grid item md={8} xs={8}>
            {isNegotiationOfferNotSent ?  model.getInputField("offer", props) : model.getOutputField("offer", props)}
        </Grid>
        <Grid item md={4} xs={4}>
            Discarded
        </Grid>
    </Grid>
}