import {fetch} from "../../../utils/dataAccess";

function download(filename, file) {
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
}
export const getAvailableUnits = ()=> fetch(`/api/units/export/availables`)
    .then(response =>
    {
        return response.blob();
    })
    .then(blobFile => {
        download("available_units.csv", blobFile )
    })
    .catch(e => {
    });



