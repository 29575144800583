import TableItem from "../classes/TableItem";
import LandlordCreate from "../../pages/createPages/LandlordCreate";


const landlordOverride = {
    "model":{
        personalData:{resource:{model:{telephone:{type:"phone"}, mobile:{type:"phone"}}}}
    },
    createPage:<LandlordCreate></LandlordCreate>,
    table:[new TableItem("personalData.name", "Name"), new TableItem("personalData.surname", "Surname"), new TableItem("personalData.email", "Email") ]
}

export default landlordOverride;