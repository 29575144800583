import React from 'react';
import Grid from "@material-ui/core/Grid";
import WorkflowPageHorizontal from "../WorkflowPageHorizontal";
import Button from "@material-ui/core/Button";
import Transition from "../../models/classes/Transition";
import {WorkflowStep} from "../../tools/workflow/workflowHelpers";


const CREATED = "created";
const FLOW_TYPE_CHOSEN = "flow_type_chosen";
const DATA_UPLOADED = "data_uploaded";
const UNSIGNED_UPLOADED = "unsigned_uploaded";
const ISSUER_UPLOADED = "issuer_uploaded";
const BENEFICIARY_UPLOADED = "beneficiary_uploaded";
const WITNESS_UPLOADED = "witness_uploaded";
const FINALIZED = "finalized";
const stepRegistry = [
    new WorkflowStep(CREATED,(props)=><ChooseFlowTypeStep {...props}/>,"Choose flow type"),
    new WorkflowStep(FLOW_TYPE_CHOSEN,(props)=><UploadDataStep {...props}/>,"Insert basic data",[new Transition("insert_basic_data", "Create holding deposit contract")]),
    new WorkflowStep(DATA_UPLOADED,(props)=><UploadUnsignedFileStep {...props}/>,"Upload unsigned",[new Transition("upload_unsigned", "")]),
    new WorkflowStep(UNSIGNED_UPLOADED,(props)=><UploadIssuerFileStep {...props}/>,"Upload issuer",[new Transition("upload_issuer", "")]),
    new WorkflowStep(ISSUER_UPLOADED,(props)=><UploadBeneficiaryFileStep {...props}/>,"Upload beneficiary",[new Transition("upload_beneficiary", "")]),
    new WorkflowStep(BENEFICIARY_UPLOADED,(props)=><UploadWitnessFileStep {...props}/>,"Upload witness",[new Transition("upload_witness", "")]),
    new WorkflowStep(WITNESS_UPLOADED,(props)=><ConfirmStep {...props}/>,"Confirm",[new Transition("finalize", "")]),
    new WorkflowStep(FINALIZED,(props)=><FinalStep {...props}/>,"Landlord contract finalized")]


export default function LandlordContractCreate(props) {
    return <WorkflowPageHorizontal stepRegistry={stepRegistry} standardProps={props} embedded />
}

function ChooseFlowTypeStep(props){
    const {formValue, setFormValue} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            <Button onClick={()=>setFormValue({...formValue, useDocusign: false, requestedTransition:"choose_flow_type"})} type="submit">Use traditional flow </Button>
            <Button onClick={()=>setFormValue({...formValue, useDocusign: true, requestedTransition:"choose_flow_type"})} type="submit">Use docusign </Button>
        </Grid>
    </Grid>
}

function UploadDataStep(props){
    const {formValue, model} = props;

    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getInputField("startDate", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("endDate", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("months", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("fee", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("rentFreePeriod", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("deposit", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("defaultPaymentDay", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("witnessFullName", props)}
        </Grid>
        <Grid item md={12} xs={12}>
            {model.getInputField("witnessEmail", props)}
        </Grid>
    </Grid>
}


function UploadUnsignedFileStep(props) {
    const {formValue, model, record} = props;
    if (record.useDocusign) {
        return <Grid container>
            The file will be uploaded by docusign. If you see this, the file has not been signed yet.
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("unsignedFile", props)}
            </Grid>
        </Grid>
    }
}

function UploadIssuerFileStep(props) {
    const {formValue, model, record} = props;
    if (record.useDocusign) {
        return <Grid container>
            {model.getOutputField("unsignedFile", props)}
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("issuerSignedFile", props)}
            </Grid>
        </Grid>
    }
}

function UploadBeneficiaryFileStep(props) {
    const {formValue, record, model} = props;
    if (record.useDocusign) {
        return <Grid container>
            {model.getOutputField("issuerSignedFile", props)}
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("beneficiarySignedFile", props)}
            </Grid>
        </Grid>
    }
}

function UploadWitnessFileStep(props) {
    const {formValue, model, record} = props;
    if (record.useDocusign) {
        return <Grid container>
            {model.getOutputField("beneficiarySignedFile", props)}
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("witnessSignedFile", props)}
            </Grid>
        </Grid>
    }
}


function ConfirmStep(props) {
    const {formValue, model} = props;
    return <Grid container>
        {model.getOutputField("witnessSignedFile", props)}
    </Grid>
}


function FinalStep(props) {
    const {formValue, model} = props;
    return <Grid container>
        {model.getOutputField("witnessSignedFile", props)}
    </Grid>
}




