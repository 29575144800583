import React from "react";
import Acquisition from "../../pages/createPages/Acquisition";
import LandlordRelationshipShow from "../../pages/showPages/LandlordRelationshipShow";
import PropertyEnquiryCreate from "../../pages/createPages/PropertyEnquiryCreate";
import LandlordCreate from "../../pages/createPages/LandlordCreate";
import LandlordRelationshipView from "../../pages/landlordRelationship/LandlordRelationshipView";
import yellow from "@material-ui/core/colors/yellow";
import {green} from "@material-ui/core/colors";
import red from "@material-ui/core/colors/red";


const  overridden_landlord_relationships = {
    "model": {
        "property": {
            "id": "property",
            "label": "Enquired Property",
            "type": "embedded_single",
            "resourceName": "properties",
            "form": <PropertyEnquiryCreate/>,
            "resource": {
                "model": {
                    "propertyName": {
                        "xs": 12,
                        "md": 6,
                        "group": "PropertyInfo",
                        "position": 1,
                        "listShow": true,
                        "label": "Property Name"
                    },
                    "area": {
                        "xs": 12,
                        "md": 6,
                        "group": "Location",
                        "position": 7,
                        "label": "Area",
                        "listShow": true
                    },
                    "askedPrice": {
                        "type": "money"
                    },
                    "currentPlace": {
                        "listShow": true,
                        "label": "State"
                    }
                },
                "table": [
                    {
                        "id": "propertyName",
                        "label": "Property Name"
                    },
                    {
                        "id": "address",
                        "label": "Address"
                    },
                    {
                        "id": "arthurProperty.id",
                        "label": "Arthur Id"
                    },
                    {
                        "id": "currentPlace",
                        "label": "Status"
                    }
                ],
                "formType": "tab-horizontal"
            }
        },
        "landlord": {
            "id": "landlord",
            "label": "Landlord",
            "type": "embedded_single",
            "resourceName": "landlords",
            "form": <LandlordCreate/>,
            "resource": {
                "model": {
                    "personalData": {
                        "resource": {
                            "model": {
                                "telephone": {
                                    "type": "phone"
                                },
                                "mobile": {
                                    "type": "phone"
                                }
                            }
                        }
                    }
                },
                "table": [
                    {
                        "id": "personalData.name",
                        "label": "Name"
                    },
                    {
                        "id": "personalData.surname",
                        "label": "Surname"
                    },
                    {
                        "id": "personalData.email",
                        "label": "Email"
                    }
                ]
            }
        },
        "firstLandlordTenancy": {
            "listShow": true,
            "optionText": "label",
        },
        "currentPlace":{
            colorMap:{
                "active": {
                    backgroundColor: green.A100
                },
                "expired":{
                    backgroundColor: red.A100
                }
            }
        }
    },
    "table": [
        {
            "id": "property.propertyName",
            "label": "Property"
        },
        {
            "id": "landlord.personalData.name",
            "label": "Landlord name"
        },
        {
            "id": "landlord.personalData.surname",
            "label": "Landlord surname"
        },
        {
            "id": "currentPlace",
            "label": "Status"
        }
    ],
    "createPage": <Acquisition/>,
    "editPage": <LandlordRelationshipView/>,
    "showPage": <LandlordRelationshipView/>
}

export default overridden_landlord_relationships;

//new TableItem("activeTenancy.landlordContract.startDate",  "Start date"),