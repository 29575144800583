import Section from "../../components/pageElements/Section";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {createStyles, Divider} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";
import {Kitchen} from "./Kitchen";
import {LivingRoom} from "./LivingRoom";
import {SharedBathroom} from "./SharedBathroom";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);


export default function PropertyEdit(props){
    const classes = useStyles();
    const {model, loading, formValue, submitHandler} = props
    return <div>

            <Section title={"Property Details"}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6} item>
                        {model.getInputField("propertyName",props)}
                    </Grid>
                    <Grid xs={12} md={6} item>
                        {model.getInputField("type",props)}
                    </Grid>
                    <Divider/>
                    <Grid xs={12} md={6} item>
                        {model.getInputField("address",props)}
                    </Grid>
                    <Grid xs={12} md={6} item>
                        {model.getInputField("city",props)}
                    </Grid>
                    <Grid xs={12} md={6} item>
                        {model.getInputField("country",props)}
                    </Grid>
                    <Grid xs={12} md={6} item>{model.getInputField("county",props)}</Grid>
                    <Grid xs={12} md={6} item>{model.getInputField("tubeStation",props)}</Grid>
                    <Grid xs={12} md={6} item>{model.getInputField("postcode",props)}</Grid>
                    <Grid xs={12} md={6} item>{model.getInputField("neighborhood",props)}</Grid>
                </Grid>
            </Section>
            <Section title={"Property Information"}>
                <Grid container spacing={2}>
                    <Grid xs={6} md={6} item>{model.getInputField("floorNumber",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("maximumOccupancy",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("rentableUnits",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("floors",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("bathrooms",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("bedrooms",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("receptions",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("hasPartition",props)}</Grid>
                    <Grid xs={6} md={6} item>{model.getInputField("partitionLocation",props)}</Grid>
                </Grid>
            </Section>

            <Section title={"Property Features"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Section  title="General" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={6} md={6} item>{model.getInputField("hasCableTV",props)}</Grid>
                                <Grid xs={6} md={6} item>{model.getInputField("hasTopUp",props)}</Grid>
                                <Grid xs={6} md={6} item>{model.getInputField("hasCentralHeating",props)}</Grid>
                                <Grid xs={6} md={6} item>{model.getInputField("hasAirConditioning",props)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                    <Grid item xs={4}>
                        <Section  title="General" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={12} md={12} item>{model.getInputField("woodenFloors",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("carpet",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("gym",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("spa",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("concierge",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("lift",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("parking",props)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                    <Grid item xs={4}>
                        <Section  title="Appliances" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={12} md={12} item>{model.getInputField("smokeAlarm",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("CODetector",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("storageRoom",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("terrace",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("patio",props)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                    <Grid item xs={4}>
                        <Section  title="Outdoor Area" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={12} md={12} item>{model.getInputField("backGarden",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("communalGarden",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("frontGarden",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("privateGarden",props)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("backGarden",props)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                    <Grid item xs={12}>
                        <Section  title="Indoor Area" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={12} md={12} item>{model.getInputField("kitchen",props, <Kitchen></Kitchen>)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("livingRoom",props, <LivingRoom></LivingRoom>)}</Grid>
                                <Grid xs={12} md={12} item>{model.getInputField("sharedBathroom",props, <SharedBathroom></SharedBathroom>)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Section  title="Images" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={12} md={12} item>{model.getInputField("images",props)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Section  title="Floorplans" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid xs={12} md={12} item>{model.getInputField("floorplans",props)}</Grid>
                            </Grid>
                        </Section>
                    </Grid>
                </Grid>
            </Section>
            <SaveLoaderButton loading={loading} onClick={submitHandler}>Save</SaveLoaderButton>


    </div>



}

