import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import {CardHeader, Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import React from "react";

const useMarginCardStyles = makeStyles({
    root: {
        width:"100%"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    content:{
        maxHeight:275,
        overflow:"auto"
    }
});


export default function OutlinedCard({title, children, actions}) {
    const classes = useMarginCardStyles();
    const bull = <span className={classes.bullet}>•</span>;

    return (
        <Card className={classes.root} variant="outlined">
            <CardHeader title={title}/>
            <CardContent className={classes.content}>
                {children}
            </CardContent>
            <CardActions>
                {actions}
            </CardActions>
        </Card>
    );
}