import React from "react";
import UnitCreate from "../../pages/createPages/UnitCreate";
import TableItem from "../classes/TableItem";
import UnitShow from "../../pages/showPages/unit/UnitShow";

const unitOverride = {

    "model":{
        "currentPlace":{ "label":"State"},
    },
    table:[
        new TableItem("unitLetter", "Letter"),
        new TableItem("property", "Property"),
        new TableItem("currentPlace", "Status"),
        new TableItem("arthurUnit.id","Arthur id",<ArthurUnitLink/> ),
        new TableItem("portals","Portals", <WordpressUnitLink/> ),
        new TableItem("availableToLet","Available"),
    ],
    "createPage":<UnitCreate/>,
    "editPage":<UnitShow/>
}

function ArthurUnitLink(props){
    const {record} = props;
    if(!isNaN(record)){
        return <a href={`https://system.arthuronline.co.uk/myrooms1/units/view/${record}`} target="_blank">{record}</a>
    }
    return "";

}

function WordpressUnitLink(props){
    const {record} = props;
    if(record.portal.name==="Myrooms"){
        // eslint-disable-next-line no-template-curly-in-string
        return <a href={`https://myrooms.co.uk/properties/${record.id}/slug`} target={"_blank"}>Link Myrooms</a>

    }

    return<></>

}


export default unitOverride;
