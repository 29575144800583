import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import WorkflowPageHorizontal from "../../WorkflowPageHorizontal";
import Transition from "../../../models/classes/Transition";
import {Typography} from "@material-ui/core";

//PLACES
const CREATED = "created";
const FLOW_TYPE_CHOSEN = "flow_type_chosen";
const DATA_UPLOADED = "data_uploaded";
const UNSIGNED_UPLOADED = "unsigned_uploaded";
const ISSUER_UPLOADED = "issuer_uploaded";
const BENEFICIARY_UPLOADED = "beneficiary_uploaded";
const FINALIZED = "finalized";

//TRANSITIONS
const BACK_TO_CREATE = "back_to_create"
const BACK_TO_DATA_UPLOADED = "back_to_data_uploaded";
const BACK_TO_UNSIGNED = "back_to_unsigned";
const BACK_TO_ISSUER = "back_to_issuer";
const CHOOSE_FLOW_TYPE = "choose_flow_type";
const INSERT_BASIC_DATA = "insert_basic_data";
const UPLOAD_UNSIGNED = "upload_unsigned";
const UPLOAD_ISSUER = "upload_issuer";
const UPLOAD_BENEFICIARY = "upload_beneficiary";
const FINALIZE = "finalize";

const stepRegistry = [
    {id: CREATED, nextTransitions:[new Transition( CHOOSE_FLOW_TYPE, "Choose flow type")], label:"Choose flow type", showComponent:(props)=><ChooseFlowTypeStep record={props.formValue} {...props}/>, editComponent:(props)=><ChooseFlowTypeStep {...props}/>},
    {id: FLOW_TYPE_CHOSEN, nextTransitions:[new Transition(INSERT_BASIC_DATA, "Contract creation")], label:"Insert contract details", showComponent:(props)=><UploadDataStep record={props.formValue} {...props}/>, editComponent:(props)=><UploadDataStep {...props}/>},
    {id: DATA_UPLOADED, nextTransitions:[new Transition(UPLOAD_UNSIGNED, "Next")], label:"Upload draft file", showComponent:(props)=><UnsignedFileUploadStep record={props.formValue} {...props}/>, editComponent:(props)=><UnsignedFileUploadStep {...props}/>},
    {id: UNSIGNED_UPLOADED, previousTransitions:["back_to_create"], nextTransitions:[new Transition(UPLOAD_ISSUER, "Next")], label:"Upload Contract signed by Issuer", editComponent:(props)=><MyroomsSignatureStep {...props}/> ,showComponent:(props)=><MyroomsSignatureStep record={props.formValue} {...props}/>},
    {id: ISSUER_UPLOADED, previousTransitions:["back_to_unsigned"], nextTransitions:[new Transition(UPLOAD_BENEFICIARY, "Finalize")], label:"Finalize contract", editComponent:(props)=><LandlordSignatureStep {...props}/>, showComponent:(props)=><LandlordSignatureStep record={props.formValue} {...props}/>,},
    {id: BENEFICIARY_UPLOADED, previousTransitions:["back_to_unsigned"], nextTransitions:[new Transition(FINALIZE, "Confirm")], label:"Confirm renewal", editComponent:(props)=><ConfirmDataStep {...props}/>, showComponent:(props)=><ConfirmDataStep record={props.formValue} {...props}/>,},
    {id: FINALIZED,previousTransitions:["back_to_issuer"], label:"Summary", editComponent:(props)=><FinalStep {...props}/>, showComponent:(props)=> <FinalStep {...props}/>}]



export default function LandlordRenewalContractCreate(props) {
    return <WorkflowPageHorizontal stepRegistry={stepRegistry} standardProps={props} embedded />
}

function ChooseFlowTypeStep(props){
    const {formValue, setFormValue} = props;
    return <Grid container>
        <Grid item md={12} xs={12}>
            <Button onClick={()=>setFormValue({...formValue, useDocusign: false, requestedTransition:"choose_flow_type"})} type="submit">Use traditional flow </Button>
            <Button onClick={()=>setFormValue({...formValue, useDocusign: true, requestedTransition:"choose_flow_type"})} type="submit">Use docusign </Button>
        </Grid>
    </Grid>
}

function UploadDataStep(props){
    const {model} = props
    return <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                {model.getInputField("startDate", props)}
            </Grid>
            <Grid item md={12} xs={12}>
                {model.getInputField("endDate", props)}
            </Grid>
            <Grid item md={12} xs={12}>
                {model.getInputField("months", props)}
            </Grid>
            <Grid item md={12} xs={12}>
                {model.getInputField("rent", props)}
            </Grid>
            <Grid item md={12} xs={12}>
                {model.getInputField("depositAmount", props)}
            </Grid>
        </Grid>
}

function UnsignedFileUploadStep(props) {
    const {formValue, model} = props;
    if (formValue.useDocusign) {
        return <Grid container>
            <Typography>
                If you're seeing this, it means that the creation of the contract is occurring via Docusign service. The contract will be uploaded soon.
            </Typography>

        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("unsignedFile", props)}
            </Grid>
        </Grid>
    }
}

function MyroomsSignatureStep(props) {
    const {formValue, model} = props;
    if (formValue.useDocusign) {
        return <Grid container>
            <Grid item xs={12} md={12}>
                <Typography>
                    If you're seeing this, it means that the creation of the contract is occurring via Docusign service. At this stage,the document was sent to a member of Myrooms' staff for signature.
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                {model.getOutputField("unsignedFile", props)}
            </Grid>
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("issuerSignedFile", props)}
            </Grid>
        </Grid>
    }
}

function LandlordSignatureStep(props) {
    const {formValue, model} = props;
    if (formValue.useDocusign) {
        return <Grid container>
            <Grid item xs={12} md={12}>
                <Typography>
                    If you're seeing this, it means that the creation of the contract is occurring via Docusign service. At this stage,the document was sent to the landlord to be signed.
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                {model.getOutputField("issuerSignedFile", props)}
            </Grid>
        </Grid>
    } else {
        return <Grid container>
            <Grid item md={12} xs={12}>
                {model.getInputField("beneficiarySignedFile", props)}
            </Grid>
        </Grid>
    }
}

function ConfirmDataStep(props) {
    const {formValue, model} = props;
    return <Grid container>
        <Grid item xs={12} md={12}>
            {model.getOutputField("beneficiarySignedFile", props)}
        </Grid>
    </Grid>

}

function FinalStep(props) {
    return <Grid container>
        <Typography>DONE!</Typography>
    </Grid>
}




