import TableItem from "../classes/TableItem";
import EnquiryCreate from "../../pages/enquiries/EnquiryCreate";
import EnquiryEdit from "../../pages/enquiries/EnquiryEdit";
import EnquiryShow from "../../pages/enquiries/EnquiryShow";

const tenantOverride = {
    table:[
        new TableItem("personalData.name", "Name"),
        new TableItem("personalData.name", "Surname"),
        new TableItem("personalData.email", "Email"),
        new TableItem("currentPlace", "Status"),
    ]
}

export default tenantOverride;