import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {authProvider} from "../../utils/authorizationProvider";
import {useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import logo from '../../logo.svg'
import {Copyright} from "../copyright/Copyright";
import {useAuthPageStyles} from "./authPagesStyle";


export default function SignIn() {
    const location = useLocation();
    const dispatch = useDispatch();

    let url = "/";
    if(location.state){
       url = location.state.referrer;
    }

    const [formValue, setFormValue] = useState({});
    const [errorMessage, setErrorMessage] = useState("ERRORE");
    const [open, setOpen] = React.useState(false);


    const handleAuthenticationError = (error) => {
        console.log("error",error);
        setErrorMessage(error.message);
        setOpen(true);
    }

    const onChangeHandler = (event) => {

        const target = event.target;
        let value = target.value;
        const name = target.id;
        setFormValue({...formValue, [name]:value});
    }



    const classes = useAuthPageStyles();
    const onSubmitHandler = (e, value) => {

        e.preventDefault();
        authProvider.login(formValue).catch((reason)=>handleAuthenticationError(reason));
        //.then(dispatch(push(url)))
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt={"logo.svg"}></img>
                    <ValidatorForm className={classes.form} onSubmit={onSubmitHandler} onError={errors => console.log(errors)}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            onChange={onChangeHandler}
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            onChange={onChangeHandler}
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                        >
                            Sign in
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/reset-password" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                            </Grid>
                        </Grid>

                    </ValidatorForm>

                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}


