import React, {useEffect, useState} from "react";
import SaveButton, {withLoader} from "./SaveButton";

export function SaveLoaderButton({loading, onClick}){
    const [localLoading, setLocalLoading] = useState(false)

    const localOnClick = (e) => {
        setLocalLoading(true);
        onClick(e)
    }

    useEffect(()=>{
        if(!loading) setLocalLoading(loading)
    },[loading])

    return withLoader(<SaveButton onClick={localOnClick}>Save</SaveButton>, localLoading)
}


