export interface TabElement{
    label:string;
    component:any;
}

export class TabElement{
    label: string;
    component:any;

    constructor({label, component}:TabElement) {
        this.label = label;
        this.component = component;
    }
}