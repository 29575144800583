import React from 'react';
import Grid from "@material-ui/core/Grid";
import WorkflowGeneric from "../WorkflowGeneric";
import NegotiationOfferCreate from "./NegotiationOfferCreate";


const stepRegistry = [
    {id: "created", nextTransitions:[], label:"Choose a property", showComponent:(props)=><CreationStep record={props.formValue} {...props}/>, editComponent:(props)=><CreationStep {...props}/>},
    {id: "pending", nextTransitions:[], label:"", showComponent:(props)=><PendingStep record={props.formValue} {...props}/>, editComponent:(props)=><PendingStep {...props}/>},
    {id: "accepted", nextTransitions:[], label:"", showComponent:(props)=><AcceptedStep record={props.formValue} {...props}/>, editComponent:(props)=><AcceptedStep {...props}/>},
    {id: "discarded", nextTransitions:[], label:"", showComponent:(props)=><StoppedStep record={props.formValue} {...props}/>, editComponent:(props)=><StoppedStep {...props}/>},
    ]


export default function NegotiationCreate(props){
    return <WorkflowGeneric isLinear={false} stepRegistry={stepRegistry} standardProps={props} embedded={props.embedded}/>
}

function CreationStep(props){

    let {formValue, model} = props;
    //const newModel = model.addPropertiesToRequestedElement({"modifyOnlyLastElement": true, editabilityRule: (item) => item.currentPlace==="created"|| item.currentPlace===undefined}, "negotiation_offers");
    props = {...props, model:model};
    const isNegotiationOfferNotSent = formValue.currentPlace === undefined || formValue.currentPlace ==="created";
    return <Grid container>
        <Grid item md={8} xs={8}>
            {isNegotiationOfferNotSent ? model.getInputField("negotiationOffers", props, <NegotiationOfferCreate {...props} embedded/>)  : model.getInputField("negotiationOffers", props, <NegotiationOfferCreate {...props} embedded/>)}
        </Grid>
    </Grid>
}

function PendingStep(props){
    const {model} = props;
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("negotiationOffers", props, <NegotiationOfferCreate {...props} embedded/>)}
        </Grid>
        <Grid item md={4} xs={4}>
            PENDING
        </Grid>
    </Grid>
}

function AcceptedStep(props){
    const {formValue, model} = props;
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("negotiationOffers", props)}
        </Grid>
        <Grid item md={4} xs={4}>
            ACCEPTED
        </Grid>
    </Grid>
}


function StoppedStep(props){
    const {formValue, model} = props;
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("negotiationOffers", props)}
        </Grid>
        <Grid item md={4} xs={4}>
            STOPPED
        </Grid>
    </Grid>
}



