import ContractCreate from "../../pages/createPages/ContractCreate";

const contractOverride = {
    "model": {
        "startDate":{listShow: true},
        "endDate":{listShow: true}
    },
    "createPage": <ContractCreate/>,
    "editPage": <ContractCreate/>
}

export default contractOverride;