import moment from "moment";

export const inputFormat = "YYYY-MM-DD";
export const readableFormat = "DD/MM/YYYY";
export const readableTimeFormat = "DD/MM/YYYY HH:mm";

export function getFormTimeFormat(timeString){
    return moment(timeString).format("YYYY-MM-DDThh:mm");
}
export function getTime(timeString){
    return moment(timeString);
}

export function getDateInputFormat(){
    return moment().format(inputFormat);
}

export function getDateBackendFormat(){
    return moment().format(readableFormat);
}


export function manipulateDate(time){
    return moment(time, "DD/MM/YYYY").format("YYYY-MM-DD")
}

export function changeTimeFormat(time, formatInput, formatOutput){
    //We suppose time is in format YYYY-MM-DD
    return moment(time, formatInput).format(formatOutput)
}

export function showDate(time){
    return changeTimeFormat(time, inputFormat, readableFormat);
}

export function changeToReadableFormat(time, formatInput){
    return changeTimeFormat(time, inputFormat, readableFormat);
}

export function changeToBackendFormat(time){
    return changeTimeFormat(time, readableFormat, inputFormat);
}
