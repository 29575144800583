import moment from 'moment'
import { Calendar as CalendarReact, momentLocalizer } from 'react-big-calendar'
import React from "react";
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment)



export default function Calendar(){
    const events = [new Event({title:"Titolo evento", startDate:moment.now(), endDate:moment.now(), allDay:false} )]
    return <div style={{margin:10}}>
        <CalendarReact
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
        />
    </div>
}


class Event {
    constructor({title, startDate, endDate, allDay, resource=null}){
        this.title = title;
        this.start = startDate;
        this.end = endDate;
        this.allDay = allDay;
        this.resource = resource
    }
}