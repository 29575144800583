import React, {useCallback, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height:"100%"
    },
    paper:{
        margin:"10px 0"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardSxRoot:{
        width: '100%',
        height:"100%",
        display: "flex",
        flexDirection: "column"
    },
    cardSxContent:{
        flexGrow:1
    }
}));

export default function WorkflowPageHorizontal({standardProps:props, stepRegistry, sideElement, embedded=false}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {formValue, record, setFormValue} = useMemo(()=>{return props;},[props]);
    const [activeStep, setActiveStep] = React.useState(0); //frontend step
    const [currentStep, setCurrentStep] = React.useState(0); //step corresponding to current transition
    const [skipped, setSkipped] = React.useState(new Set());


    const setCurrentStepFunction = useCallback(()=>{

        if(record.currentPlace){
            console.log("currentplace", record.currentPlace)
            const element = stepRegistry.find(item => item.id === record.currentPlace);
            setCurrentStep(stepRegistry.indexOf(element))
        }
    },[formValue, stepRegistry])

    const moveToNextState = (nextTransitionIndex) => {
        const {formValue, partialSubmitHandler, submitHandler} = props;
        formValue.requestedTransition = stepRegistry[currentStep]["nextTransitions"][nextTransitionIndex]["value"];
        if(embedded){
            submitHandler().then((responseFormvalue) => {
                console.log("response form value", responseFormvalue)

            }).catch(response => {
            } );
        }else{
            partialSubmitHandler(formValue).then((responseFormvalue) => {
                if(formValue.id){
                    setFormValue(responseFormvalue);
                    setActiveStep(activeStep => activeStep+1)
                }else{
                    dispatch(push(`/${props.resourceName}/${responseFormvalue.id}/edit`))
                }

            }).catch(()=>{
            });
        }


    }

    const moveToPreviousState = (previousTransitionIndex) => {
        const {formValue, partialSubmitHandler} = props;
        formValue.requestedTransition = stepRegistry[activeStep+1]["previousTransitions"][previousTransitionIndex];
        partialSubmitHandler(formValue).then((responseFormvalue) => {
            setFormValue(responseFormvalue);
        }).catch(()=>{
        });
    }


    const getStepContent = (activeStep, currentStep, props) => {
        if(activeStep < currentStep){
            return <div>{stepRegistry[activeStep].showComponent(props)}</div>
        }
        return stepRegistry[activeStep].editComponent(props);
    }

    const getStepPreviousButtons = (activeStep) => {
        return stepRegistry[activeStep].previousTransitions.map((previousTransition, previousTransitionIndex) => <Button onClick={()=>moveToPreviousState(previousTransitionIndex)}>EDIT</Button>)
    }

    const getStepNextButtons = (activeStep) => {

        if(stepRegistry[activeStep].nextTransitions.length!==1){
            return stepRegistry[activeStep].nextTransitions.map((nextTransition, nextTransitionIndex) => <Button
                variant="contained"
                color="primary"
                onClick={()=> handleNext(nextTransitionIndex)}
                className={classes.button}
            >
                {activeStep === steps.length - 2 ? nextTransition.label : nextTransition.label}
            </Button>)
        }else{
            return stepRegistry[activeStep].nextTransitions.map((nextTransition, nextTransitionIndex) => <Button
                variant="contained"
                color="primary"
                onClick={()=> handleNext(nextTransitionIndex)}
                className={classes.button}
            >
                {activeStep === steps.length - 2 ? 'Finish' : "Next"}
            </Button>)
        }

    }


    useEffect(()=> setCurrentStepFunction(),[formValue])
    useEffect(()=>{setActiveStep(currentStep)},[currentStep])


    const steps = stepRegistry.map((step) => step.label);

    const isStepOptional = (step) => {
        return false;
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = (nextTransitionIndex) => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if(currentStep===activeStep){
            moveToNextState(nextTransitionIndex);
        }else{
            setActiveStep(activeStep => activeStep+1)
        }


    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item md={ sideElement ? 8 : 12}>
                <Card className={classes.cardSxRoot}>
                    <CardContent className={classes.cardSxContent}>
                        <Stepper alternativeLabel={true} activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All steps completed
                                    </Typography>
                                </div>
                            ) : (
                                <div>
                                    <Typography className={classes.instructions}>{getStepContent(activeStep, currentStep, props)}</Typography>
                                    <div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                            Back
                        </Button>
                        {isStepOptional(activeStep) && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSkip}
                                className={classes.button}
                            >
                                Skip
                            </Button>
                        )}
                        {activeStep < currentStep && <Button onClick={moveToPreviousState}>EDIT</Button>}

                        {activeStep < steps.length-1 &&
                        getStepNextButtons(activeStep)}
                    </CardActions>
                </Card>
            </Grid>
            {sideElement && <Grid item md={4}>
                <Card className={classes.root}>
                    <CardContent>
                        {sideElement}
                    </CardContent>
                </Card>
            </Grid>}
        </Grid>

    );
}


