import TableItem from "../classes/TableItem";
import EnquiryCreate from "../../pages/enquiries/EnquiryCreate";
import EnquiryEdit from "../../pages/enquiries/EnquiryEdit";
import EnquiryShow from "../../pages/enquiries/EnquiryShow";
import React from "react";
import {EnquiryView} from "../../pages/enquiries/EnquiryView";
import {changeTimeFormat, readableTimeFormat} from "../../utils/timeManager";

const enquiryOverride = {
    table:[
        new TableItem("name", "Name"),
        new TableItem("surname", "Surname"),
        new TableItem("email", "Email"),
        new TableItem("fCreatedAt", "Created at", <TimeStamp/> ),
        new TableItem("assignedRoom", "Assigned room", <AssignedRoom/> ),
        new TableItem("isPrebooking", "Prebooking" ),
        new TableItem("currentPlace", "Status" ),

    ],
    createPage:<EnquiryCreate></EnquiryCreate>,
    editPage:<EnquiryView></EnquiryView>,
    showPage:<EnquiryView></EnquiryView>
}

export default enquiryOverride;


function AssignedRoom({record}){
    return <div>{record?.shortname}</div>
}

function TimeStamp({record}){
    return (record) ? changeTimeFormat(record, "YYYY-MM-DD HH:mm:ss", readableTimeFormat): <div>No date</div>;
}
