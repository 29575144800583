import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import DefaultPaymentItemCreate from "../../pages/defaultPaymentItem/DefaultPaymentItemCreate";

const defaultPaymentItemOverride = {
    "model":{
        "name":{"listShow":true, "label": "Name"},
        "fee":{"listShow":true, "label": "Fee", "adornment":<InputAdornment position="start">£</InputAdornment>}
    },
    "editPage":<DefaultPaymentItemCreate/>,
    "showPage": <DefaultPaymentItemCreate/>,
    "createPage":<DefaultPaymentItemCreate/>,
}

export default defaultPaymentItemOverride