import Grid from "@material-ui/core/Grid";
import React from "react";
import {getTime} from "../../utils/timeManager";
import moment from "moment";
import SimpleCard from "../../components/pageElements/SimpleCard";

export default function (props){
    const {record, model} = props;

    const {landlordContract} = (record);
    const {startDate, endDate} = (landlordContract)
    const momentNow = moment.now();
    const remainingDays = getTime(endDate).diff(getTime(momentNow), 'days');
    const days = getTime(momentNow).diff(getTime(startDate), 'days');

    return <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
            {model.getOutputField("landlordContract", props, <LandlordContract/>, false)}
        </Grid>
        <Grid item xs={12} md={6}>
            {model.getOutputField("holdingDepositContract", props, <HoldingDepositContract/>, false) }
        </Grid>
    </Grid>
}

function LandlordContract(props){
    const {model} = props;
    return <Grid container>
        <Grid item xs={6} md={3}>
            {model.getOutputField("startDate", props)}
        </Grid>
        <Grid item xs={6} md={3}>
            {model.getOutputField("endDate", props)}
        </Grid>
        <Grid item xs={6} md={3}>
            {model.getOutputField("fee", props)}
        </Grid>
        <Grid item xs={6} md={3}>
            {model.getOutputField("months", props)}
        </Grid>
    </Grid>
}

function HoldingDepositContract(props){
    const {model, record} = props;
    const {hasBreakClause} = (record)


    return <SimpleCard title={"Holding Deposit"}>
        <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
                {model.getOutputField("holdingDeposit", props)}
            </Grid>
            {hasBreakClause ?
            <>
                <Grid item xs={6} md={6}>
                    {model.getOutputField("breakExercisable", props)}
                </Grid>
                <Grid item xs={6} md={6}>
                    {model.getOutputField("party", props)}
                </Grid>
                <Grid item xs={6} md={6}>
                    {model.getOutputField("breakNoticePeriod", props)}
                </Grid>
            </> :
                <Grid item xs={6} md={6}>
                    {model.getOutputField("hasBreakClause", props)}
                </Grid>
            }
            {}
            <Grid item xs={6} md={6}>
                {model.getOutputField("securityDeposit", props)}
            </Grid>
            <Grid item xs={6} md={6}>
                {model.getOutputField("rentFrequency", props)}
            </Grid>
            <Grid item xs={6} md={6}>
                {model.getOutputField("rentPaymentMethod", props)}
            </Grid>
            <Grid item xs={6} md={6}>
                {model.getOutputField("beneficiarySignedFile", props)}
            </Grid>
        </Grid>
    </SimpleCard>
}

/*Holding Deposit


Rent Frequency

Not defined
Rent Frequency Modality

Not defined
Rent Payment Method

Not defined
Is Deposit Required

Current Place

Not defined
Unsigned File

No file
Issuer Signed File

No file
Beneficiary Signed File

No file
Start Date

Invalid date*/