import {createTheme} from "@material-ui/core";

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: "#50e3c2",
            light: "rgba(80, 227, 194,0.1)"

        },
        secondary: {
            main: "#ff7977",
            light: "#ff9392"
        },
    },
    typography: {
        color:"",
        fontFamily:['Josefin Sans', 'sans-serif']
    },
    overrides: {
        // Style sheet name ⚛️
        MuiListItem: {
            "root": {
                "&$selected": {
                    "backgroundColor": "#50e3c2",
                    "color":"white"
                }
            }
        }

    }
});

export const darkTheme = createTheme({
    palette: {
        type: 'dark',
    }
});

const themeRegistry = {
    "light": mainTheme,
    "dark": darkTheme
}

export function getThemeByName(name){
    return themeRegistry[name];
}
