import TableItem from "../classes/TableItem";
import PostcodeEdit from "../../pages/postcodes/PostcodeEdit";
import PostcodeCreate from "../../pages/postcodes/PostcodeCreate";

const postcodeOverride = {
    table:[new TableItem("postcode", "Postcode"), new TableItem("city", "City")],
    createPage: <PostcodeCreate></PostcodeCreate>,
    editPage: <PostcodeEdit></PostcodeEdit>,
}

export default postcodeOverride;
