import React from "react";
import LandlordTenancyCreate from "../../pages/createPages/LandlordTenancyCreate";
import ContractCreate from "../../pages/createPages/ContractCreate";


const landlordTenancyOverride = {
    "model":{
        "contract":{form: <ContractCreate/>},
        "payments":{listShow:true, type:"embedded", cardinality:"multiple", resourceName:"landlord_tenancy_payments", xs:12, md:12, label:"Payments", group:"Units", position:1}
    },
    "createPage":<LandlordTenancyCreate/>,
    "editPage":<LandlordTenancyCreate/>
}

export default landlordTenancyOverride