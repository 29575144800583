import React, {useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Col2Layout from "../components/templates/Col2Layout";
import Grid from "@material-ui/core/Grid";
import {CardHeader, Typography} from "@material-ui/core";
import {ResponsivePie} from '@nivo/pie'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Calendar from "../components/calendar/Calendar";


export default function(){
    return <Col2Layout right={<RightPanel/>} left={<LeftPanel/>}>
        </Col2Layout>
}



function LeftPanel(){

    const data = [
        {label: "New enquiries", value: 4},
        {label: "New applicants", value: 4},
        {label: "New tenants issues today", value: 4},
        {label: "Outstanding tenant issues", value: 4},
        {label: "Incorrect tenancy status", value: 4},
        {label: "Romms rentend this month", value: 4},
        {label: "Rooms rented last 30 days", value: 4},
        {label: "Rooms rented las month", value: 4},
        {label: "Renewals this month", value: 4},
        {label: "Renewals last month", value: 4},
        {label: "Renewals next month", value: 4},
        {label: "Average loss/unit", value: 4},
        {label: "Urgent issues", value: 4},
        ]

    return <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            <Paper>
                <Grid container>
                    {data.map(({label, value}) =>
                        <>
                            <Grid item xs={2} md={2}>
                                <NumberShower value={value} label={label} />
                            </Grid>
                        </>
                        )}
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
            <Paper>
                <Grid container>
                    <Grid item xs={12} md={6}><div style={{height:400}}><Pie/></div></Grid>
                    <Grid item xs={12} md={6}>
                        <Rooms/>
                        <Tenancies/>
                    </Grid>
                </Grid>
            </Paper>

        </Grid>
        <Grid item xs={12} md={12}><Paper style={{height:500}}><Calendar></Calendar></Paper></Grid>

    </Grid>
}


const useNumberShowerStyles = makeStyles({
    value: {
        fontSize: 30
    },
    label: {
        maxWidth: 150,
        textAlign: "center"
    }

});


function NumberShower({value, label, link}){
    const classes = useNumberShowerStyles();
    return <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", margin:10}}>
        <Typography color="primary" variant="h4">{value}</Typography>
        <Typography className={classes.label}>{label}</Typography>
    </div>
}

function LineNumberShower({value, label, link}){
    return <Grid container>
        <Grid item xs={4} md={4}>
            <Typography color="primary">{value}</Typography>
        </Grid>
        <Grid item xs={8} md={8}>
            <Typography>{label}</Typography>
        </Grid>

    </Grid>
}

function Pie(){

    const data = [
        {
            "id": "sass",
            "label": "sass",
            "value": 543,
            "color": "hsl(103, 70%, 50%)"
        },
        {
            "id": "scala",
            "label": "scala",
            "value": 18,
            "color": "hsl(149, 70%, 50%)"
        },
        {
            "id": "haskell",
            "label": "haskell",
            "value": 8,
            "color": "hsl(112, 70%, 50%)"
        },
        {
            "id": "rust",
            "label": "rust",
            "value": 287,
            "color": "hsl(160, 70%, 50%)"
        },
        {
            "id": "java",
            "label": "java",
            "value": 425,
            "color": "hsl(276, 70%, 50%)"
        }
    ];
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
    return <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'nivo' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: 'color' }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'ruby'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'c'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'go'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'python'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'scala'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'lisp'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'elixir'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'javascript'
                    },
                    id: 'lines'
                }
            ]}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
}


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function CardDisplay({title, cardContent}){
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>

    return  <Card className={classes.root} variant="outlined">
        <CardHeader title={title}/>
        <CardContent>
            {cardContent}
        </CardContent>
    </Card>
}

function Rooms(){
    const data = [
        {label: "Occupied", value: 154},
        {label: "Vacant", value: 35},
        {label: "Unavailable", value: 4},
    ]
    const content = <Grid container>
        {data.map(({value, label}) => <Grid item xs={12} md={6}><LineNumberShower value={value} label={label}/></Grid>)}
    </Grid>

    return <CardDisplay title={"ROOMS"} cardContent={content}/>
}

function Tenancies(){
    const data = [
        {label: "Prospective", value: 154},
        {label: "Move in", value: 35},
        {label: "Approved", value: 4},
        {label: "Move out", value: 4},
        {label: "Ending", value: 4},
        {label: "Expiring", value: 4},
        {label: "Waiting for signature", value: 4}
    ]
    const content = <Grid container>
        {data.map(({value, label}) => <Grid item xs={12} md={6}><LineNumberShower value={value} label={label}/></Grid>)}
        </Grid>

    return <CardDisplay title={"TENANCIES"} cardContent={content}/>
}


function RightPanel(){
    return <Grid container>
        <Grid item xs={12} md={12}>
            <OutlinedCard title="Messages"/>
        </Grid>
        <Grid item xs={12} md={12}>
            <OutlinedCard title="Notifications"/>
        </Grid>
        <Grid item xs={12} md={12}>
            <OutlinedCard title="Favourite reports"/>
        </Grid>
    </Grid>
}


const useMarginCardStyles = makeStyles({
    root: {
        width:"100%"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    content:{
        maxHeight:275,
        overflow:"auto"
    }
});

export function OutlinedCard({title}) {
    const classes = useMarginCardStyles();
    const bull = <span className={classes.bullet}>•</span>;

    return (
        <Card className={classes.root} variant="outlined">
            <CardHeader title={title}/>
            <CardContent className={classes.content}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Word of the Day
                </Typography>
                <Typography variant="h5" component="h2">
                    be{bull}nev{bull}o{bull}lent
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    adjective
                </Typography>
                <Typography variant="body2" component="p">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Word of the Day
                </Typography>
                <Typography variant="h5" component="h2">
                    be{bull}nev{bull}o{bull}lent
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    adjective
                </Typography>
                <Typography variant="body2" component="p">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Word of the Day
                </Typography>
                <Typography variant="h5" component="h2">
                    be{bull}nev{bull}o{bull}lent
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    adjective
                </Typography>
                <Typography variant="body2" component="p">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Word of the Day
                </Typography>
                <Typography variant="h5" component="h2">
                    be{bull}nev{bull}o{bull}lent
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    adjective
                </Typography>
                <Typography variant="body2" component="p">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
}