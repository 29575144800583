import React from "react";
import Section from "../../components/pageElements/Section";
import Grid from "@material-ui/core/Grid";

export default function LandlordCreate(props){

    const {model} = props;
    console.log("model", model);
    return <Section title={"Landlord Details"}>
        <Grid container spacing={2}>
            <Grid md={12} xs={12} item>
                {model.getInputField("mainContact",props)}
            </Grid>
            <Grid md={12} xs={12} item>
                {model.getInputField("personalData",props, <PersonalDataCreate/>)}
            </Grid>
        </Grid>
    </Section>
}

function PersonalDataCreate(props){
    const {model} = props;

    return <Grid container spacing={2}>
        <Grid item xs={6}>{model.getInputField("name", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("surname", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("email", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("address1", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("address2", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("postcode", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("postcode2", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("telephone", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("mobile", props)}</Grid>
        <Grid item xs={6}>{model.getInputField("nino", props)}</Grid>
    </Grid>

}