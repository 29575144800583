import TableItem from "../classes/TableItem";
import React from "react";
import CityCreate from "../../pages/city/CityCreate";


const citiesOverride = {
    table:[new TableItem("name", "City name")],
    createPage:<CityCreate/>,
    editPage:<CityCreate/>,
}

export default citiesOverride
