import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import WorkflowPageVertical from "../WorkflowPageVertical";


const stepRegistry = [
    {id: "created", nextTransitions:[{value:"input_basic_details", label:""}], label:"Choose a property", showComponent:(props)=><ShowFirstStep record={props.formValue} {...props}/>, editComponent:(props)=><FirstStep {...props}/>},
    {id: "basic_details_set", previousTransitions:["back_to_create", "blabla"], nextTransitions:[{value:"start_negotiation", label:"Start negotiation"}], label:"", editComponent:(props)=><SecondStep {...props}/> ,showComponent:(props)=><ShowSecondStep record={props.formValue} {...props}/>},
    {id: "contract_creation_started", previousTransitions:["back_to_unsigned"], nextTransitions:[{value:"approve_contract", label:"Approve contract"}], label:"Start contract", editComponent:(props)=><ThirdStep {...props}/>, showComponent:(props)=><ThirdStep {...props}/> },
    {id: "contract_creation_ended", previousTransitions:["back_to_unsigned"], nextTransitions:[{value:"finalize_tenancy", label:"Insert fee"}], label:"", editComponent:(props)=><ContractEndStep {...props}/>, showComponent:(props)=><ContractEndStep {...props}/>},
    {id: "tenancy_details", previousTransitions:["back_to_unsigned"], nextTransitions:[{value:"activation", label:"Activate tenancy"}], label:"Finalize tenancy", editComponent:(props)=><FourthStep {...props}/>, showComponent:(props)=><FourthStep {...props}/>},
    {id: "active", previousTransitions:["back_to_unsigned"], nextTransitions:[], label:"End", editComponent:(props)=><FifthStep {...props}/>, }
    ]


export default function LandlordTenancyCreate(props){
    return <WorkflowPageVertical standardProps={props} stepRegistry={stepRegistry} />
}



function FirstStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("landlordProperty", props)}
        </Grid>
    </Grid>
}

function ShowFirstStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getOutputField("landlordProperty", props)}
        </Grid>
    </Grid>
}

function SecondStep(props){
    return <Typography>Does the property have to go through an acquisition process?</Typography>
}

function ShowSecondStep(props){
    return <Typography>Does the property have to go through an acquisition process?</Typography>
}


function ThirdStep(props){
    const {model} = props

    return model.getInputField("contract", props)
}

function FourthStep(props){
    const {model} = props
    return <Grid container>
        <Grid item md={8} xs={8}>
            {model.getInputField("fee", props)}
        </Grid>
        <Grid item md={8} xs={8}>
            {model.getInputField("months", props)}
        </Grid>
        <Grid item md={8} xs={8}>
            {model.getInputField("defaultPaymentDay", props)}
        </Grid>
    </Grid>
}

function FifthStep(){
    return <Typography>Tenancy is active</Typography>
}

function ContractEndStep(){
    return <Typography>Contract is finished</Typography>
}



