import {isAnyWorkflowStates} from "../../tools/workflow/workflowHelpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import TenantRelationshipNew from "./TenantRelationshipNew";
import TenantRelationshipShow from "./TenantRelationshipShow";

export default function (props){
    const {record} = props;


    const isNotWorkflow = isAnyWorkflowStates(record,  [["active", "ending"],["active"], ["expired"]])

    if(record.currentPlace){
        return (isNotWorkflow) ? <TenantRelationshipShow {...props}/> : <TenantRelationshipNew {...props}/>
    }
    return <div>
        <CircularProgress />
    </div>
}