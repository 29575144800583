import SimpleCard from "../../components/pageElements/SimpleCard";
import React from "react";
import PersonIcon from '@material-ui/icons/Person';

export default function (props) {

    const {record} = props

    const title = `Tenant: ${record?.personalData?.name} ${record?.personalData?.surname}`
    return <SimpleCard title={title} avatar={<PersonIcon/>}/>
}