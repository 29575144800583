import React, {useState} from "react";
import {fetch} from "../../utils/dataAccess";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import PropertyListItem from "./menuItems/PropertyListItem";
import {useDebouncedCallback} from "use-debounce";
import LandlordListItem from "./menuItems/LandlordListItem";
import LandlordRelationshipListItem from "./menuItems/LandlordRelationshipListItem";
import UnitListItem from "./menuItems/UnitListItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";


export function SearchComponent({classes}){
    const initialData = {}
    const [data, setData] = useState(initialData);
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const search = (query)=> fetch(`/search?search=${query}`)
        .then(response =>
        {
            return response.json()

        })
        .then(response => {
            setOpen(true)
                setData(response)
        })
        .catch(e => {
        });


    const debouncedQuery = useDebouncedCallback(
            (event) => {
            const target = event.target;let value = target.value;
            if(value!=="" && value !== undefined){
                search(value)
                setAnchorEl(event.target)
            }else{
                setData(initialData)
                setAnchorEl(null)}
        },1000)


    const reducer = (accumulator, [type, value]) => {
        const newValues = value.map(item => {return {...item, searchKey:type}});
        return [...accumulator, ...newValues]
    }
    const finalData = Object.entries(data).reduce(reducer, []);

    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search…"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={debouncedQuery.callback}
                onClick={event => setAnchorEl(event.target)}
            />
        </div>
        <Popover
                id="customized-menu"
                anchorEl={anchorEl}
                disableAutoFocus
                disableEnforceFocus
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <List
                    style={{width:800}}
                    component="nav" aria-label="main mailbox folders">

                    {finalData.length !== 0 ? finalData.map(item => getStyledListItem(item)) : <ListItem><ListItemText primary={"No items found"}></ListItemText></ListItem>}
                </List>
            </Popover>

    </>
}

function getStyledListItem(item){
    switch (item.searchKey){
        case "properties":{
            return <PropertyListItem property={item}/>
        }
        case "landlords":{
            return <LandlordListItem landlord={item}/>
        }
        case "landlordRelationships":{
            return <LandlordRelationshipListItem landlordRelationship={item}/>
        }
        case "units":{
            return <UnitListItem unit={item}/>
        }
        default:
            return <div></div>
    }
}