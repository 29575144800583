import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React from "react";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {showDate} from "../../utils/timeManager";
import {getFromMap} from "react-frontend-generator/dist/utils/mapUtils";
import SimpleCard from "../pageElements/Card";

export default function ({tenancies}){

    const timelineItems = Array.from(tenancies.values()).map((tenancy) => {

        const card = <SimpleCard title={`${showDate(tenancy?.landlordContract?.startDate)} - ${showDate(tenancy?.landlordContract?.endDate)}`}>
            Contenuto
        </SimpleCard>

        return <TimelineItem>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{card}</TimelineContent>
        </TimelineItem>
    })

    return <Timeline>
        {timelineItems.length!== 0 ? timelineItems : <div>No history for this tenancy was found</div>}
    </Timeline>
}