import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {useLocation} from 'react-router-dom';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {useParams} from "react-router";
import {routes} from "../../utils/routes";
import logo from "../../logo.svg";
import {Copyright} from "../copyright/Copyright";
import {useAuthPageStyles} from "./authPagesStyle";


export default function ChangePassword() {
    const location = useLocation();

    const {token} = useParams();
    let url = "/";
    if(location.state){
        url = location.state.referrer;
    }

    const [password, setPassword] = useState();

    const onSubmitHandler = (token) => {
        const request = new Request(routes.resetPassword(token), {
            method: 'POST',
            body: JSON.stringify({password:password}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })

    }

    const onChangeHandler = (event) => setPassword(event.target.value);

    const classes = useAuthPageStyles();

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt={"logo.svg"}></img>
                    <ValidatorForm className={classes.form} onSubmit={()=>onSubmitHandler(token)} onError={errors => console.log(errors)}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            onChange={onChangeHandler}
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                        >
                            Reset password
                        </Button>

                    </ValidatorForm>

                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
            {/*<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>*/}
        </>
    );
}


