import FirstLandlordTenancyCreate from "../../pages/createPages/FirstLandlordTenancyCreate";
import HoldingDepositContractCreate
    from "../../pages/landlordFirstTenancy/holdingDepositContract/HoldingDepositContractCreate";


const firstLandlordTenancyOverride = {
    "model":{
        "holdingDepositContract":{form: <HoldingDepositContractCreate/>}
    },
    "createPage":<FirstLandlordTenancyCreate/>,
    "editPage":<FirstLandlordTenancyCreate/>
}

export default firstLandlordTenancyOverride;