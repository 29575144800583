import Grid from "@material-ui/core/Grid";
import React, {useState} from "react";
import {Typography} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ButtonsHorizontalList from "../../components/buttons/ButtonsHorizontalList";
import {push} from 'connected-react-router';
import PhoneIcon from '@material-ui/icons/Phone';
import SimpleCard from "../../components/pageElements/SimpleCard";
import {withLoader} from "../../components/buttons/SaveButton";
import {useDispatch} from "react-redux";
import MyroomsDialog from "../../components/MyroomsDialog";
import {SaveLoaderButton} from "../../components/buttons/SaveLoaderButton";

export default function EnquiryEdit(props){
    const {model, formValue, setFormValue, partialSubmitHandler, submitHandler, loading} = props
    const dispatch = useDispatch();
    const name = formValue?.name;
    const surname = formValue?.surname;
    const phone = formValue?.phoneNumber;
    const isPreBooking = formValue?.isPrebooking;
    const [open, setOpen] = useState(false);
    const [openReject, setOpenReject] = useState(false);


    const approve = () => {
        const newFormValue = formValue.updateFormValue("requestedTransition", "approve");
        setFormValue(newFormValue)
        partialSubmitHandler(newFormValue).then(response => {
            const {tenantRelationshipId} = response
            window.location.href = `/tenant_relationships/${tenantRelationshipId}/edit`
        })
    }
    const reject = () => {
        const newFormValue = formValue.updateFormValue("requestedTransition", "reject");
        setFormValue(newFormValue)
        partialSubmitHandler(newFormValue)
    }

    const openApproveModal = () => setOpen(!open);
    const openRejectModal = () => setOpenReject(!openReject);

    const style = {padding:10}
    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Paper style={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={10}>
                            <Typography color="secondary" variant="h4">{isPreBooking ? "Pre booking" : "Enquiry"} by {name} {surname} </Typography>
                            <Grid container>
                                <Grid item xs={1} md={1}><PhoneIcon></PhoneIcon>
                                </Grid>
                                <Grid item xs={11} md={11}>{phone}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Grid container>
                                <Grid xs={12} md={6}>
                                    <Button fullWidth onClick={openApproveModal} color="primary" variant="contained">Approve</Button>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Button fullWidth onClick={openRejectModal} color="secondary" variant="contained">Reject</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
                <SimpleCard title="Prospective tenant details">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("name", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("surname", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("email", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("phoneNumber", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("tenantType", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("nationality", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("occupation", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
                <SimpleCard title="Room/location">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("portal", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("enquiredRoom", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("neighborhood", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("assignedRoom", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
                <SimpleCard title="Period/Price">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("startDate", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("endDate", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("weeklyPrice", props)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("holdingDepositPrice", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Grid>
            <Grid item xs={12} md={12}>
                <SimpleCard title="Notes">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {model.getInputField("specialRequirements", props)}
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Grid>
            <Grid item xs={12} md={12}>
                <SaveLoaderButton loading={loading} onClick={submitHandler} ></SaveLoaderButton>
            </Grid>
        </Grid>
        <MyroomsDialog title={"Approvation of enquiry"} open={open} handleClose={() => setOpen(!open)} actions={
            <ButtonsHorizontalList>
                <Button color={"secondary"} variant={"contained"} onClick={() => setOpen(!open)}>Cancel</Button>
                {withLoader(<Button color={"primary"} variant={"contained"} onClick={approve}>Confirm approve</Button>, loading)}
            </ButtonsHorizontalList>
        }>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"body2"}>Before approving the enquiry, please double check the following information, weekly price and assigned room in particular.</Typography>
                    <Typography variant={"body2"}>The weekly price will be used to issue a unique payment for the holding deposit.</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {model.getInputField("startDate", props)}
                </Grid>
                <Grid item xsparte con eval={12} md={6}>
                    {model.getInputField("endDate", props)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {model.getInputField("weeklyPrice", props)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {model.getInputField("holdingDepositPrice", props)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {model.getInputField("assignedRoom", props)}
                </Grid>
            </Grid>
        </MyroomsDialog>

        <MyroomsDialog title={"Are you sure you want to reject this enquiry?"} open={openReject} handleClose={() => setOpenReject(!openReject)} actions={
            <ButtonsHorizontalList>
                <Button color={"secondary"} variant={"contained"} onClick={() => setOpenReject(!openReject)}>Cancel</Button>
                {withLoader(<Button color={"primary"} variant={"contained"} onClick={reject}>Reject enquiry</Button>, loading)}
            </ButtonsHorizontalList>
        }>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                </Grid>
            </Grid>
        </MyroomsDialog>
    </>
}
